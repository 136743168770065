<template>
  <navbar v-if="application === 'GENOVESA'" class="fixed-top" v-model="showNavbar" :transparent="false" type="bioxsys">
    <template v-if="token > ''" slot="navbar-menu-l">
      <div class="logo">
        <a class="" aria-label="logo" href="https://www.bioxsys.cz/">
          <div class="logo-img">
              <img src="/img/bioxsys_negative.svg" alt="">
          </div>
        </a>
      </div>

      <div class="genovesa"> {{ application }} <br /> <span class="genovesa_small"> &nbsp;&nbsp;&nbsp;{{ app_version }}</span>
      </div>
    </template>

    <template v-else slot="navbar-menu-l">
      <div class="genovesa">
        <a class="" href="https://www.bioxsys.cz/" target="_blank"> {{ application }} by BIOXSYS</a>
      </div>
    </template>

    <template v-if="token > ''" slot="navbar-menu-m">
      <el-menu
        :default-active="$route.name"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelectApplication"
        background-color="#005d72"
        text-color="#fff"
        active-text-color="#ffd04b"
        :router="true"
        >
        <el-submenu>
          <template slot="title"> APPLICATIONS </template>
          <el-menu-item v-if="'pro' in apps" :disabled="!apps.pro" index="proProjectsInternal" :route="{name:'proProjectsInternal'}"> <i class="icofont-tasks"></i> Genomics </el-menu-item>

          <el-menu-item v-if="'comgens' in apps" :disabled="!apps.comgens" index="proComgens" :route="{name:'proComgens'}"> <i class="icofont-tasks"></i> Comparative Genomics </el-menu-item>

          <el-menu-item v-if="'fastras' in apps" :disabled="!apps.fastras" index="fastrasProjectsInternal" :route="{name:'fastrasProjectsInternal'}"> <i class="icofont-tasks"></i> Fastgen </el-menu-item>

          <el-menu-item v-if="'genetify' in apps" :disabled="!apps.genetify" index="genetifyProjectsInternal" :route="{name:'genetifyProjectsInternal'}"> <i class="icofont-tasks"></i> Microbiome </el-menu-item>

          <!-- <el-submenu v-if="false" index="Patients">
            <template slot="title"> <i class="icofont-users"></i> Patients </template>
            <el-menu-item index="proPatientsAll" :route="{name:'proPatientsAll'}"> <i class="icofont-users"></i> All Patients </el-menu-item>
            <el-menu-item index="proStatim" :route="{name:'proStatim'}"> <i class="icofont-users-alt-3"></i> By Statim </el-menu-item>
            <el-menu-item index="proByVariant" :route="{name:'proByVariant'}"> <i class="icofont-users-alt-5"></i> By Variant </el-menu-item>
          </el-submenu> -->

          <!-- <el-menu-item index="proDashboard" :route="{name:'proDashboard'}"> <i class="icofont-dashboard-web"></i> Dashboard </el-menu-item> -->
        </el-submenu>

        <!-- <el-submenu v-if="'light' in apps" index="light" :disabled="!apps.light">
          <template slot="title"> LIGHT </template>
          <el-submenu index="Projects">
            <template slot="title"> <i class="fas fa-user-friends"></i> Projects </template>
            <el-menu-item index="lightProjectsInternal" :route="{name:'lightProjectsInternal'}"> <i class="icofont-tasks"></i> All Projects </el-menu-item>
          </el-submenu>

          <el-menu-item index="lightVariantsAll" :route="{name:'lightVariantsAll'}"> <i class="icofont-dna"></i> Variants </el-menu-item>
          <el-menu-item index="lightDashboard" :route="{name:'lightDashboard'}"> <i class="icofont-dashboard-web"></i> Dashboard </el-menu-item>
        </el-submenu> -->

        <!-- <el-submenu v-if="'fastras' in apps" index="fastras" :disabled="!apps.fastras">
          <template slot="title"> FASTGEN </template>
          <el-menu-item index="fastrasProjectsInternal" :route="{name:'fastrasProjectsInternal'}"> <i class="icofont-tasks"></i> Projects </el-menu-item>
        </el-submenu>

        <el-submenu v-if="'genetify' in apps" index="genetify" :disabled="!apps.genetify">
          <template slot="title"> MICROBIOME </template>
          <el-menu-item index="genetifyProjectsInternal" :route="{name:'genetifyProjectsInternal'}"> <i class="icofont-tasks"></i> Projects </el-menu-item>
          <el-menu-item> <a :href="subject.genetify_link" target="_blank" class="genetify_link"><i class="icofont-tasks"></i> Files </a></el-menu-item>
        </el-submenu> -->

        <!-- <el-submenu v-if="'sport' in apps" index="sport" :disabled="!apps.sport">
          <template slot="title"> SPORT </template>
          <el-submenu index="Projects">
            <template slot="title"> <i class="fas fa-user-friends"></i> Projects </template>
            <el-menu-item index="sportProjectsInternal" :route="{name:'sportProjectsInternal'}"> <i class="icofont-tasks"></i> All Projects </el-menu-item>
          </el-submenu>

        </el-submenu> -->

      </el-menu>

    </template>

    <template slot="navbar-menu">
      <!-- <form v-if="token > ''">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>
      </form> -->

      <div class="dolu_subject">
        <el-tag v-if="token > ''" type="info" effect="plain" class="tag_user">
          {{ user.f_name }} {{ user.l_name }} - {{ subject.subject }}
        </el-tag>
      </div>

      <ul class="navbar-nav dolu_subject">
        <li class="nav-item" v-if="false">
          <router-link class="nav-link btn-magnify" to="/">
            <i class="icofont-live-support"></i>
            <p>
              <span class="d-lg-none d-md-block"> </span>
            </p>
          </router-link>
        </li>

        <drop-down v-if="token > ''" icon="fas fa-user" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title"
            slot-scope="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            >
            <i class="icofont-user"></i>
            <span class="d-lg-none d-md-block"> </span>
          </a>
          <a class="dropdown-item" @click="m_modal_passwd(true)"> Change your password </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="a_join_subject()"> Join to existing profile </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="a_logout()"> Sign out </a>
        </drop-down>

        <drop-down v-else icon="far fa-user" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title"
            slot-scope="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            >
            <i class="far fa-user nav-icon"></i>
            <span class="d-lg-none d-md-block"> </span>
          </a>
          <a class="dropdown-item" @click="m_modal_login(true)">Sign in</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="m_modal_forgot(true)">Forgot your password?</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="m_modal_reg(true)"> Registration of a new customer </a>
        </drop-down>

      </ul>
    </template>
  </navbar>

  <navbar v-else class="fixed-top" v-model="showNavbar" :transparent="false" type="biovendor">
    <template v-if="token > ''" slot="navbar-menu-l">
      <div class="logo">
        <a class="" aria-label="logo" href="https://biovendor.group/">
          <div class="logo-img">
              <img src="/img/bv/NGS_Genovesa.svg" alt="">
          </div>
        </a>
      </div>

      <div v-if="application === 'NGS-GENOVESA'" class="genovesa_small"> {{ app_version_biovendor }} </div>
      <div v-else-if="application === 'NGS-GENOVESA-DEMO'" class="genovesa_small"> {{ app_version }} </div>
    </template>

    <template v-else slot="navbar-menu-l">
      <div class="genovesa">
        <img src="/img/bv/Genovesa_by_BioVendor_Group.svg" alt="">
      </div>
    </template>

    <template v-if="token > ''" slot="navbar-menu-m">
      <el-menu
        :default-active="$route.name"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelectApplication"
        background-color="#1C3552"
        text-color="#fff"
        active-text-color="#FFDD00"
        :router="true"
        >
        <el-submenu>
          <template slot="title"> APPLICATIONS </template>
          <el-menu-item v-if="'pro' in apps" :disabled="!apps.pro" index="proProjectsInternal" :route="{name:'proProjectsInternal'}"> <i class="icofont-tasks"></i> Genomics </el-menu-item>
          <el-menu-item v-if="'comgens' in apps" :disabled="!apps.comgens" index="proComgens" :route="{name:'proComgens'}"> <i class="icofont-tasks"></i> Comparative Genomics </el-menu-item>
          <el-menu-item v-if="'fastras' in apps" :disabled="!apps.fastras" index="fastrasProjectsInternal" :route="{name:'fastrasProjectsInternal'}"> <i class="icofont-tasks"></i> Fastgen </el-menu-item>
          <el-menu-item v-if="'genetify' in apps" :disabled="!apps.genetify" index="genetifyProjectsInternal" :route="{name:'genetifyProjectsInternal'}"> <i class="icofont-tasks"></i> Microbiome </el-menu-item>

          <!-- <el-submenu v-if="false" index="Patients">
            <template slot="title"> <i class="icofont-users"></i> Patients </template>
            <el-menu-item index="proPatientsAll" :route="{name:'proPatientsAll'}"> <i class="icofont-users"></i> All Patients </el-menu-item>
            <el-menu-item index="proStatim" :route="{name:'proStatim'}"> <i class="icofont-users-alt-3"></i> By Statim </el-menu-item>
            <el-menu-item index="proByVariant" :route="{name:'proByVariant'}"> <i class="icofont-users-alt-5"></i> By Variant </el-menu-item>
          </el-submenu> -->

          <!-- <el-menu-item index="proDashboard" :route="{name:'proDashboard'}"> <i class="icofont-dashboard-web"></i> Dashboard </el-menu-item> -->
        </el-submenu>


      </el-menu>

    </template>

    <template slot="navbar-menu">
      <!-- <form v-if="token > ''">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>
      </form> -->

      <div class="dolu_subject">
        <span v-if="token > ''" class="user_info">
          {{ user.f_name }} {{ user.l_name }} | {{ subject.subject }}
        </span>
      </div>

      <ul class="navbar-nav dolu_subject">
        <li class="nav-item" v-if="false">
          <router-link class="nav-link btn-magnify" to="/">
            <i class="icofont-live-support"></i>
            <p>
              <span class="d-lg-none d-md-block"> </span>
            </p>
          </router-link>
        </li>

        <drop-down v-if="token > ''" icon="fas fa-user" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title"
            slot-scope="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            >
            <i class="icofont-user"></i>
            <span class="d-lg-none d-md-block"> </span>
          </a>
          <a class="dropdown-item" @click="m_modal_passwd(true)"> Change your password </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="a_join_subject()"> Join to existing profile </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="a_logout()"> Sign out </a>
        </drop-down>

        <drop-down v-else icon="far fa-user" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title"
            slot-scope="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            >
            <i class="far fa-user nav-icon"></i>
            <span class="d-lg-none d-md-block"> </span>
          </a>
          <a class="dropdown-item" @click="m_modal_login(true)">Sign in</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="m_modal_forgot(true)">Forgot your password?</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="m_modal_reg(true)"> Registration of a new customer </a>
        </drop-down>

      </ul>
    </template>
  </navbar>
</template>

<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import {Select, Option, Tag, Menu } from 'element-ui'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import axios from 'axios';
  import VueNotify from 'vue-notifyjs'
  import VueRouter from 'vue-router'

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tag.name]: Tag,
      [Menu.name]: Menu,
    },


    data() {
      return {
        activeNotifications: true,
        showNavbar: false,

      }
    },


    computed: {
      ...mapState({
        modal_login: state => state.modal_login,
        modal_reg: state => state.modal_reg,
        modal_forgot: state => state.modal_forgot,
        modal_passwd: state => state.modal_passwd,
        id_user: state => state.id_user,
        token: state => state.token,
        id_subject: state => state.id_subject,
        user: state => state.user,
        subject: state => state.subject,
        application: state => state.application,
        apps: state => state.apps,
        app: state => state.app,
        app_version: state => state.app_version,
        app_version_biovendor: state => state.app_version_biovendor,

      }),


      ...mapGetters({

      }),
    },


    methods: {
      ...mapMutations({
        m_modal_login: 'm_modal_login',
        m_modal_reg: 'm_modal_reg',
        m_modal_forgot: 'm_modal_forgot',
        m_modal_passwd: 'm_modal_passwd',
        m_token: 'm_token',
        m_id_user: 'm_id_user',
        m_app: 'm_app',

      }),


      ...mapActions({
        a_logout: 'a_logout',
        a_test: 'a_test',
        a_join_subject: 'a_join_subject',

      }),


      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },


      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },


      closeDropDown() {
        this.activeNotifications = false
      },


      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },


      handleSelectApplication(key, keyPath) {
        this.m_app(keyPath[0]);
      },

    },

  }
</script>

<style scoped>
  .nav-icon{
    font-size: 16px;
    margin-right: 5px;
    color: white;
  }

  navbar{
    background-color: #333 !important;
  }

  el-select {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .select-warning.el-select .el-input input {
    border-radius: 0px !important;
    color: gray !important;
    background-color: white !important;
  }
  .white {
    color: white !important;
  }

  .genovesa {
    font-size: 25px !important;
    font-weight: 900 !important;
    line-height: 0.80;
    text-align: center !important;
    margin-left: 20px !important;
    margin-top: 10px !important;
    color: white !important;
  }

  .genovesa_small {
    z-index: 999 !important;
    font-size: 11px !important;
    line-height: 1.00;
    font-weight: 600 !important;
    color: rgb(188, 236, 245) !important;
    padding-top: 40px !important;
    margin-left: -90px !important;
  }

  .dolu_subject {
    padding-top: 6px !important;
  }

  .user_info {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 2.35;
    height: 50px;
    color: #C6CCD3;
    text-align: center !important;
    padding-right: 30px;
  }

  .el-submenu {
    font-size: 16px !important;
    font-weight: 800 !important;
  }

  .el-submenu__title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }

  .el-submenu-item {
    font-size: 16px !important;
    font-weight: 800 !important;
  }

  .el-menu-item {
    font-size: 16px !important;
    font-weight: 800 !important;
  }

  .genetify_link {
    color: white !important;
    text-decoration: none !important;
  }

</style>
