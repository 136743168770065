import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueNotify from 'vue-notifyjs';
import router from '../main.js';
import FileSaver from 'file-saver';
import { Loading } from 'element-ui';

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueNotify, { type: 'danger', timeout: 5000, horizontalAlign: 'center', verticalAlign: 'top', });

let notifier = new Vue();

const store = new Vuex.Store({
    state: {
        modal_login: false,
        modal_reg: false,
        modal_forgot: false,
        modal_passwd: false,
        modal_info: false,
        modal_info_text: "",
        modal_terms: false,
        modal_interpretation: false,
        modal_transcripts: false,
        modal_new_project: false,
        modal_project_detail: false,
        modal_project_stat: false,
        modal_patient: false,
        modal_exam_qc: false,
        modal_exam_detail: false,
        modal_exam_files: false,
        modal_exam_ext_detail: false,
        modal_exam_msi_tmb: false,
        modal_acmg: false,
        modal_freq: false,
        modal_hpo_terms: false,
        modal_predictors: false,

        id_user: localStorage.getItem('current_user'),
        token: localStorage.getItem('token'),
        id_biorole: 0,
        roles: [],
        my_role: 0,

        apps: {
            "pro": true,
            "light": true,
            "fastras": false,
            "neon": false,
            "lilis": true,
            "endos": false,
        },
        app: "",
        rapp: "",
        app_version: "v. 1.7.0",
        app_version_biovendor: "v. 1.7",

        ws: null,

        user: {
            id_user: 0,
            email: "",
            nick: "",
            f_name: "",
            l_name: "",
            degrees_1: "",
            degrees_2: "",
            phone: "",
            last_role: 0,
        },

        user_setup: {
            vars_grid_size: "L",
            vars_grid_page: 20,

        },

        subject: {
            id_subject: 0,
            subject: "",
            ic: "",
            hash: "",
            street: "",
            city: "",
            post_code: "",
            country: "",
            phone: "",
            email: "",
            dic: "",
            genetify_link: "",
            mongo_server: 0,
        },

        client_img: "img/client_0.jpg",

        privat_be: "/pv",
        application: "",
        current_url: "",
        subversion: "",
        vars_tab_name: "b",

        terms_and_conditions: `
        <div title="header">
        <table width="604" cellpadding="7" cellspacing="0">
          <col width="187"/>

          <col width="187"/>

          <col width="187"/>

          <tr valign="top">
            <td width="187" style="border: none; padding: 0cm"><p style="margin-left: -0.2cm">
              <br/>

              </p>
            </td>
            <td width="187" style="border: none; padding: 0cm"><p align="center">
              <br/>

              </p>
            </td>
            <td width="187" style="border: none; padding: 0cm"><p align="right" style="margin-right: -0.2cm">
              <br/>

              </p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom: 0cm; line-height: 100%"><br/>

        </p>
      </div><p lang="en-GB" align="center" style="margin-left: 1.64cm; text-indent: -0.64cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%; page-break-before: always">
      <font face="Arial, sans-serif"><b>Terms and Conditions</b></font></p>
      <p lang="en-GB" align="center" style="margin-left: 1.64cm; text-indent: -0.64cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-bottom: 0.28cm; line-height: 107%">
        <font face="Arial, sans-serif"><b>Interpretation and Definitions </b></font>
        </p>
      </ol>
      <p align="justify" style="margin-left: 1.91cm; margin-bottom: 0.28cm; line-height: 107%">

      </p>
      <ol type="a">
        <li><p lang="en-GB" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><u>Interpretation</u></font></p>
      </ol>
      <p lang="en-GB" align="justify" style="margin-left: 1.5cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif">The words of which the initial letter
      is capitalized have the below-mentioned meanings. The following
      definitions shall have the same meaning regardless of whether they
      appear in singular or in plural.</font></p>
      <ol type="a" start="2">
        <li><p style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB"><u>Definitions</u></span></font><font face="Arial, sans-serif"><span lang="en-GB">:</span></font></p>
      </ol>
      <p lang="en-GB" style="margin-left: 1.62cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif">For the purposes of these Terms and
      Conditions:</font></p>
      <ol>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        &ldquo;<font face="Arial, sans-serif"><span lang="en-GB"><b>T&amp;C</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&rdquo;
        shall mean these Terms and Conditions for GENOVESA Services
        governing the rights and obligations of Provider and of Customer
        using GENOVESA Services, when registering in GENOVESA App on address
        </span></font><font color="#0000ff"><u><a href="https://ngs-genovesa.biovendor.group/" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">https://ngs-genovesa.biovendor.group</span></font></a></u></font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        &ldquo;<font face="Arial, sans-serif"><span lang="en-GB"><b>Licensor</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&rdquo;
        shall mean Bioxsys s.r.o. (Identification No./IČO: </span></font><font face="Arial, sans-serif"><span lang="en-GB">08280231</span></font><font face="Arial, sans-serif"><span lang="en-GB">)
        registered in the Commercial Register maintained with </span></font><font face="Arial, sans-serif"><span lang="en-GB">Regional
        Court in Ústí and Labem, File Number 43825, e-mail contact:
        </span></font><font color="#0000ff"><u><a href="mailto:info@bioxsys.com" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">info@bioxsys.com</span></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">
        is a developer of NGS software named GENOVESA (</span></font><font face="Arial, sans-serif">Research
        use only).</font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font>
        </p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        &ldquo;<font face="Arial, sans-serif"><span lang="en-GB"><b>Provider</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&ldquo;
        shall mean BioVendor MDx a.s. (Identification No./IČO:</span></font><font face="Arial, sans-serif"><span lang="en-GB">
        29268605)</span></font><font face="Arial, sans-serif"><span lang="en-GB">
        registered in the Commercial Register maintained with the </span></font><font face="Arial, sans-serif"><span lang="en-GB">Regional
        Court in Brno, File Number B 6314, e-mail contact:
        </span></font><font color="#0000ff"><u><a href="mailto:info@biovendor-mdx.com" target="_top"><font color="#000000"><font face="Arial, sans-serif"><span lang="en-GB">info@biovendor-mdx.com</span></font></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">
        is the exclusive licence holder of GENOVESA software (hereinafter
        referred to as &ldquo;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>GENOVESA
        App</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&ldquo;)
        provide a NGS services (hereinafter referred to as &ldquo;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>GENOVESA</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>Services</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&ldquo;).
        </span></font>
        </p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The terms and conditions between
        Licensor and Provider are governed by a separate agreement entitled
        &quot;LICENSE AND OTHER COOPERATION AGREEMENT&quot;. The Licensor
        may have access to personal data of natural persons in connection
        with the performance, the Licensor will act as a processor of
        personal data in the performance under this Agreement and is obliged
        to handle personal data in accordance with the legal regulations
        governing the protection of personal data, in particular in
        accordance with Act No. 110/2019 Coll, on the processing of personal
        data, and Regulation (EU) 2016/679 of the European Parliament and of
        the Council of 27 April 2016 on the protection of natural persons
        with regard to the processing of personal data and on the free
        movement of such data and repealing Directive 95/46/EC.</font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Segoe UI, sans-serif"><font size="2" style="font-size: 9pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB"><span style="font-style: normal">Since
        the Provider is a member of the BioVendor Group companies, the
        Licensor hereby expressly agrees to grant the below-mentioned
        companies (within the BioVendor Group) the right to use the GENOVESA
        software: ViennaLab </span></span></font></font></font></font><font face="Arial, sans-serif"><span lang="en-GB">Diagnostics
        GmbH</span></font><font face="Segoe UI, sans-serif"><font size="2" style="font-size: 9pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB"><span style="font-style: normal">
        (Vienna, Austria).</span></span></font></font></font></font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font>
        </p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">&quot;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>Customer</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&quot;
        shall mean any (natural or legal) person who orders and uses the
        GENOVESA Services and the GENOVESA App.</span></font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">&quot;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>End
        Users</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&quot;
        shall mean the individuals who are permitted by Customer to use the
        Services and/or GENOVESA App. For clarity, End Users may include
        employees of Customer affiliates and other authorized third parties.</span></font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">To use GENOVESA App, the Customer
        must:</font></p>
        <ul>
          <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif"><span lang="en-GB">Conclude a
          contract for the use of GENOVESA Services with Provider via
          completing registration to GENOVESA App (hereinafter referred to as
          &ldquo;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>Contract</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&rdquo;).
          During the registration of the Customer a profile is created in
          GENOVESA App (hereinafter referred to as &ldquo;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>Customer
          profile</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&rdquo;
          or &ldquo;</span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>Customer
          account</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">&rdquo;).</span></font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">Accept the T&amp;C in full extent
          during the registration to GENOVESA App by checking the checkbox on
          registration form.</font></p>
        </ul>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">Actual and valid
        version of T&amp;C is available on provider web:
        </span></font><font color="#0000ff"><u><a href="https://ngs-genovesa.biovendor.group/" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">https://ngs-genovesa.biovendor.group</span></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">/terms.</span></font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider also reserves the right to
        test for a limited period and/or for a specific market(s) and/or for
        a specific group of Customers new services, functions, interfaces,
        or products (&quot;beta testing&quot;) related to GENOVESA Services
        or GENOVESA App without any notice.</font></p>
      </ol>
      <ol type="I">
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB"><b>GENOVESA</b></span></font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font><font face="Arial, sans-serif"><span lang="en-GB"><b>App</b></span></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer can access the GENOVESA
        services through the Customer account at GENOVESA App.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Details about available services are
        provided in GENOVESA App.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Minimum configuration requirements
        for using of the GENOVESA App are met is the responsibility of
        Customer.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Training is offered via a range of
        methods including face to face and telephone sessions or online
        mmetings. Customer should contact Provider to arrange mutually
        agreeable methods, dates, and times.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider shall take all possible
        measures to achieve permanent availability of Services, and in case
        of failures, undertakes all necessary remedial actions without undue
        delay. Due to force majeure or due to the necessary maintenance,
        repairs, or other measures on the technical devices of provider or
        the technical devices of third parties providing data, content,
        information, or transmission capacity it will nevertheless lead to
        unavoidable temporary failures, interruptions, or reduction in the
        performance (speed) of services. Furthermore, an intensive
        simultaneous use of services may lead to an impairment of the
        performance (speed).</font></p>
      </ol>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Order of Services or GENOVESA App
        and Pricing</b></font></p>
      </ol>
      <ul>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">Ordering and
        pricing of Services and/or GENOVESA App. based on an individual
        offer between the Customer and the Provider.</span></font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">Customer can
        contact Provider for </span></font><font face="Arial, sans-serif"><span lang="en-GB">individual
        offer via email: </span></font><font color="#0000ff"><u><a href="mailto:genovesa@biovendor-mdx.com" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">genovesa@biovendor-mdx.com</span></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font>
        </p>
      </ul>
      <p align="justify" style="margin-left: 1.27cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Updates, Services and Support</b></font></p>
      </ol>
      <p lang="en-GB" align="justify" style="margin-left: 1.27cm; margin-bottom: 0.28cm; line-height: 107%">
      <br/>
      <br/>

      </p>
      <ul>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">Provider provides
        phone/email technical support (</span></font><font color="#0000ff"><u><a href="mailto:support@biovendor-mdx.com" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">support@biovendor-mdx.com</span></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">).</span></font></p>
      </ul>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider is performing a development
        and update of GENOVESA App.</font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">The purpose of the
        updates is primarily to adapt the application to the new versions of
        the</span></font><font face="Times New Roman, serif"><font size="5" style="font-size: 18pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">
        </span></font></font></b></font></font><font face="Arial, sans-serif"><span lang="en-GB">of
        bioinfomatics softwares and annotation databases</span></font><font face="Arial, sans-serif"><span lang="en-GB">.</span></font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The purpose of the development is to
        add new functionalities to the application. New functions and
        modules and their assignment for individual types depend on the
        provider's decision.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Provider is entitled to limit or
        suspend the operation of the GENOVESA App for the time strictly
        necessary to perform the update. The Provider is not responsible for
        any financial losses of the Customer caused by the suspension of the
        application due to the update.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The provider is not responsible for
        the issues in relation to connecting the product to any third-party
        programs.</font></p>
      </ol>
      <p lang="en-GB" align="justify" style="margin-left: 1.27cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <p lang="en-GB" align="justify" style="margin-left: 1.27cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Security</b></font></p>
      </ol>
      <ul>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider and Licensor are entitled to
        take appropriate measures to defend against threats from
        cyberattacks to safety, life, health, privacy, property, assets, and
        other legal interests of the Customer, third parties or provider
        itself. These measures may lead to restrictions in provider
        services. Depending on the severity of the risks and/or significance
        of the legal interests under threat, temporary blocking of
        individual or all services may be advisable.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider and Licensor use appropriate
        technical and organisational security measures to protect Customer
        data against accidental or intentional manipulation, partial or
        complete loss, destruction or against unauthorised access by third
        parties. Security measures are continuously improved in line with
        technological developments.</font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">In addition,
        Provider and Licensor commit each of employees to data protection
        and confidentiality in accordance with the European Data Protection
        Regulation (GDPR).</span></font></p>
      </ul>
      <p lang="en-GB" align="justify" style="margin-left: 1.27cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Duration of the Contract</b></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The duration of the Contract for the
        provision of GENOVESA services is defined by the existence of an
        active account in the GENOVESA App.</font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">The duration of
        the contract is defined with each customer and their contract with
        </span></font><b><font face="Arial, sans-serif"><span lang="en-GB">BioVendor
        MDx a.s. </span></font></b>
        </p>
      </ol>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Contract Termination</b></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The provider may terminate the
        Contract without giving a reason. The notice period is 2 months and
        runs from the day following the delivery of the notice to the
        Customer.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The provider reserves the right to
        transfer the Contract regarding the use of GENOVESA services
        (without changing the contractual provisions in any other respect)
        to another company within the BioVendor Group the Customer already
        provides to provider with his/her consent for this purpose. Provider
        shall notify the Customer in writing and in advance.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">In the event of termination of the
        Contract, the Customer is obliged to export and back up all the
        data, backups and other data uploaded to the GENOVESA app.</font></p>
      </ol>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>User rights, Use of data</b></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer receives the
        non-exclusive right to use GENOVESA services, the related content
        and information provided by the services during the period with an
        active subscription of GENOVESA services. The Customer is not
        permitted to disclose and/or distribute the content and information
        acquired through GENOVESA App services to third parties (the
        exception is End User).</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Provider shall protect the
        Customer's personal data and use them, pursuant to prior and express
        consent given by the Customer, in compliance with the applicable
        generally binding legal regulations; details for the Customer are
        given in Information on Personal Data Processing. The Customer
        grants to the provider the non-exclusive, time-limited,
        unrestricted, transferable, and sub-licensable right to use other
        data, in particular technical data, which either have no personal
        reference or whose personal details have been removed (anonymised
        data). In this context, no personal data will be disclosed and any
        trace of the anonymised data to the Customer is excluded.</font></p>
      </ol>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Responsibility</b></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer undertakes to ensure
        that the use of GENOVESA Services does not violate the provisions of
        these T&amp;C, legal requirements, third party rights or moral
        standards. This shall also apply if he/she grants third parties
        access to GENOVESA App or GENOVESA services.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer must refrain from any
        misuse of the application; in particular, it may not incorporate the
        application or any part of it into other websites, whether private
        or commercial, or distribute them commercially.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">In the event of a breach of the
        obligations set out in this article, the Customer is liable for any
        damage caused to the provider, as well as for damage caused to third
        parties. This is especially applicable if the Customer use the
        service to send testing phishing emails to email addresses without
        the permission of the company/domain owner.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer must not use the
        provider products and services to spread Spam and Phishing that
        could harass the recipient or morally and mentally damage. </font>
        </p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Furthermore, the Provider is not
        liable for defects or damage, including interruption of service
        provision and loss or damage of data caused by:</font></p>
      </ol>
      <ol type="I">
        <ol type="a">
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">by entering incorrect data into the
          application by the Customer, incorrect procedure of the Customer
          when inserting information or files into the application or
          incorrect interpretation of the data presented by the service,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">by infecting the Customer's local
          network or his computers with computer viruses (spyware, malware,
          etc.) or a hacker attack or other similar external attack,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">damage caused by a malfunction of
          hardware, operating system, or network, due to damage caused by a
          malfunction of third-party programs that run concurrently with the
          supplied software</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">force majeure, equipment failure,
          electric power failure, internet connection failure caused by the
          connection provider or attack on the network by a third party,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">incorrect system operation by the
          Customer or because of an attack on third-party server due to
          non-compliance with security standards,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">infringement of copyrights,
          trademark rights, trade name rights and other rights protected by
          Czech law caused by the Customer,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">choosing an inappropriate password
          by the Customer or by improper password storage,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">third party to whom the Customer has
          given access to the application or services,</font></p>
          <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
          <font face="Arial, sans-serif">not making regular backups properly.</font></p>
        </ol>
      </ol>
      <ol start="6">
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Segoe UI, sans-serif"><font size="2" style="font-size: 9pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB"><span style="font-style: normal">For
        the purpose of this T&amp;C, force majeure shall be deemed to be any
        event</span></span></font></font></font></font><font face="Arial, sans-serif"><span lang="en-GB">
        beyond the control of the contract parties, such as a state of war,
        legal restrictions on exports and imports, strikes, sabotage,
        natural disasters, pandemics and other factors beyond the provider's
        reasonable discretion or reasonable control which prevents the
        fulfilment of the concluded Contract.</span></font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Contract parties are obliged to
        inform the other party about the occurrence of circumstances that
        prevents the fulfilment of the concluded Contract without undue
        delay, otherwise it loses the right to claim the consequences of
        these circumstances. At the end of these circumstances, the party
        concerned is obliged to immediately inform the other party of the
        alternative date for Contract fulfilment. The Provider shall fulfill
        the obligation to this paragraph by publishing information on the
        occurrence / termination of these circumstances on its official
        product status page.</font></p>
      </ol>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>User Management, Roles, and Access
        </b></font>
        </p>
      </ol>
      <ul>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">GENOVESA App support user access and
        role management for organizations. This means that the Customer as
        an owner of organization that he created in the GENOVESA App can
        also assign an access and role for his organization to another
        person registered in GENOVESA App. </font>
        </p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">GENOVESA App support also user access
        and role management for entities. This means that the Customer as an
        owner of organization and Entity that he created in the GENOVESA App
        can also assign an access and role for his entity to another person
        registered in GENOVESA App (e.g., the Customer's employees, third
        parties managing the Customer's security, etc.). </font>
        </p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">The creation of
        additional users and their rights is upon request, after which the
        Provider will create another account with assigned rights. Ordering
        and pricing of new user accounts is based on an individual offer
        between the Customer and the Provider. Customer can contact Provider
        for </span></font><font face="Arial, sans-serif"><span lang="en-GB">individual
        offer via email: </span></font><font color="#0000ff"><u><a href="mailto:genovesa@biovendor-mdx.com" target="_top"><font face="Arial, sans-serif"><span lang="en-GB">genovesa@biovendor-mdx.com</span></font></a></u></font><font face="Arial, sans-serif"><span lang="en-GB">
        </span></font>
        </p>
      </ul>
      <ul>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Roles assigned to another people for
        management of organizations or entities may include privileged
        access rights that enable the assigned person to manage the account
        completely including billing for organization. Customer is fully
        responsible for any actions performed by the user that he assigned
        with role to his organization or entity. Customer must inform what
        type of account he needs to create by Provider.</font></p>
        <li><p lang="en-GB" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">These T&amp;C apply similarly to
        other users assigned to organization or entity.</font></p>
      </ul>
      <p lang="en-GB" style="margin-left: 0.64cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><b>Copyright and confidentiality</b></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Copyrights, as well as other
        intellectual property rights, relating to the software products,
        including manuals, manuals and other documents distributed with the
        software products, remain with the respective entities as their
        holders and are not affected by the contract and these conditions.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer is not entitled to
        reproduce the application for the purpose of its distribution,
        expand or in any way communicate to third parties, rent, or lend,
        unless the provider has given his prior express and written consent.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Customer may not modify, reverse
        engineer, recompile, convert from the application source code,
        access the source code, or make the application source code
        available to a third party.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer is also obliged to
        comply with all restrictions on the use of the software stipulated
        by law, the contract, and these conditions.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Customer may not remove, alter,
        obscure, or otherwise interfere with any copyrights or other
        designations of the legal entities located or stored on the Software
        Products, or any parts, or documentation distributed with the
        Software Products.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The copyright for the delivered
        product, accessories and documentation belongs to the respective
        author. All logos, registered trademarks, trademarks, other brands,
        and product names belong to their respective owners.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer shall ensure that third
        parties will not be informed in any way without the prior written
        consent of the provider of the scope and performance of the contract
        and the documents belonging to it. The Customer acknowledges that
        the information is confidential in the sense of &sect; 1730 of the
        Civil Code.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Provider is obliged to maintain
        confidentiality of all material facts obtained during its activities
        arising from the contract, in particular of the facts that
        constitute trade secrets and confidential information of the
        Customer.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Without the express permission of the
        provider, partial or complete copying of the provider's price lists,
        brochures, photographs, catalogues, technical data, etc. is
        prohibited. Customers are not entitled to any rights in case of any
        errors or inaccurate data contained in these materials.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The subject software as an author's
        work and the databases included in it enjoy the protection of Act
        no. No. 121/2000 Sb., Copyright Act and Act. No. 40/2009 Sb.,
        Criminal Code. The Customer is entitled to use it only to the extent
        and in the manner specified by the provider.</font></p>
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB">In the event of
        the Customer's interference with the Licensor's copyrights, the
        Licensor and Provider are entitled to a contractual penalty in the
        amount of EUR 4,000 (in words, four thousand Euros). </span></font><font face="Segoe UI, sans-serif"><font size="2" style="font-size: 9pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB"><span style="font-style: normal">The
        contractual penalty is due and payable within 15 (fifteen) days of
        the date on which the Customer is notified of the interference.</span></span></font></font></font></font><font face="Segoe UI, sans-serif"><font size="2" style="font-size: 9pt"><i><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">
        </span></font></font></i></font></font><font face="Arial, sans-serif"><span lang="en-GB">Paying
        the penalty shall not affect the right to compensation of damages
        and other statutory sanctions.</span></font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">In addition to the right to a
        contractual penalty, in the event of Customer interference with his
        copyright, the provider is entitled to copyright law, in particular
        the right to refrain from further copyright infringement, the right
        to information on the manner and extent of unauthorized use of
        software and the right to remove the consequences of copyright,
        including the provision of reasonable redress and the issuance of
        any unjust enrichment.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">All information communicated to
        Licensor or Provider by the customer, and which are referred to as
        either confidential or whose confidential nature as is evident from
        the personal data of patients of customers is confidential
        information pursuant to this provision. Confidential information is
        carefully protected by Licensor and used only in the context of the
        contractual relationship. Provider and Licensor ensures that
        confidential information will be made available only to those
        employees and other service providers that are committed to
        confidentiality to fulfill the contract.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Provider recommends the client to
        make findings and / or treatment data anonymous or pseudonymize them
        in order to separate them from the immediate patient data so that no
        reference to individuals is apparent or can be made by Provider and
        Licensor or third parties.</font></p>
      </ol>
      <ol type="I">
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB"><b>Personal Data
        Processing, Cookies and Google Analytics</b></span></font></p>
      </ol>
      <p lang="en-GB" align="justify" style="margin-left: 1.91cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ul>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Information on Personal Data
        Processing with description of Services are available in Supplement
        1.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Cookies and Google Analytics are not
        used.</font></p>
      </ul>
      <p lang="en-GB" align="justify" style="margin-left: 1.27cm; margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <br/>
      <br/>

      </p>
      <ol type="I">
        <li><p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif"><span lang="en-GB"><b>Final
        Provisions</b></span></font></p>
      </ol>
      <ol>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Unless otherwise agreed in a specific
        contract, all business relations between the provider and the
        Customer are governed by these conditions. The relevant provisions
        of the Copyright Act and other legal regulations governing the use
        of computer programs and databases and sanctions for their illegal
        use are not affected by the contract and these conditions.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Provider reserves the right, at
        his sole discretion, to modify or replace T&amp;C at any time. The
        Provider is obliged to publish a new version of the terms and
        conditions on its website/documentation without undue delay and send
        notification to the Customer's e-mail address. If the Customer does
        not withdraw from the contract within 14 days, it is considered that
        the Customer accepts these modifications of T&amp;C in full.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Each party is obliged to inform the
        other party without delay of all facts not stated in the contract
        that may affect the performance of obligations under the contract.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The Customer is not entitled to
        transfer his rights and obligations under the contract, either in
        part or in whole.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">Should individual provisions of this
        Agreement be ineffective or become ineffective, the effectiveness of
        the other provisions shall not be affected. Instead of the invalid
        provision, provisions shall be applied which, as far as possible,
        correspond to the economic purpose of the contract while adequately
        preserving the mutual interests.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">The exclusive place of jurisdiction
        for all claims arising from the contract for the use of GENOVESA
        services is the general court competent according to the registered
        office of the provider.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">All disputes arising from the
        contract for the use of GENOVESA services are governed exclusively
        by the law of the Czech Republic; the application of the United
        Nations Convention on Contracts for the International Sale of Goods
        (CISG) is excluded.</font></p>
        <li><p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
        <font face="Arial, sans-serif">These T&amp;C are valid and effective
        as of Jul 8, 2022.</font></p>
      </ol>
      <p lang="en-GB" align="center" style="margin-bottom: 0.28cm; line-height: 107%">
      <font face="Arial, sans-serif"><b>Supplement 1</b></font></p>
      <h1 lang="en-GB" class="western" align="center"><font color="#000000"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><b>Privacy
      policy</b></font></font></font></h1>
      <h2 lang="en-GB" class="western" align="justify"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Information
      of personal data processing</font></font></h2>
      <p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">In
      this text we would like to inform you about processing of your
      personal data by company BioVendor MDx a.s. (as a controller). We
      would like to also inform you about your rights with this processing
      activity.</font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">Personal
      data of registered users</span></font></font></b></font></font></p>
      <p lang="en-GB" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">The
      processor is storing personal data of registered users and that is
      name, surname, email, phone number.<br/>
      Purpose of personal data is
      provide NGS service of NGS data.<br/>
      Storage period for this
      personal data is 5 years after least recent log-in to GENOVESA app.</font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">Your
      rights</span></font></font></b></font></font></p>
      <p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">You
      have right of requesting access, rectification, erasure, and
      restriction of processing of your personal data from the controller.
      You have also right to data portability and right to object against
      data processing. You have right to lodge a complaint with a
      supervisory authority. You&rsquo;re not obliged to provide mentioned
      personal data, but without it we will not have all the data needed to
      process the service.</font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">BioVendor
      MDx a.s.</span></font></font></b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">,
      appreciates the trust of its clients and is ready to protect their
      personal information against potential abuse. The information is
      collected solely for the purpose of improving our services. We
      guarantee that the information collected will not be passed over to
      any third party (with expectation of member of BioVendor Group -
      ViennaLab and Bioxsys s.r.o.).  </span></font></font><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">BioVendor
      MDx a.s</span></font></font></b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">.
      commit each of employees to data protection and confidentiality in
      accordance with the European Data Protection Regulation (GDPR).</span></font></font></font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">BioVendor
      MDx a.s.</span></font></font></b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">,
      applies international personal information protection legislation.</span></font></font></font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB"><b>Provision
      of personal information </b></span></font></font></font></font>
      </p>
      <p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">If
      you become BioVendor Customer, you grant your approval with handling
      of your personal information when registering. All provided
      information is handled exclusively in compliance with the standards
      and procedures stipulated by law.</font></font></p>
      <p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">The
      users have the right to obtain, free of charge, information about the
      personal data stored about them. In addition, the users have the
      right to rectification of inaccurate data, to blocking and erasure of
      their personal data, insofar as this is compatible with statutory
      data retention periods.</font></font></p>
      <h2 lang="en-GB" class="western" align="justify"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Provision
      of information to other persons</font></font></h2>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">We
      really appreciate the information you provided to </span></font></font><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">BioVendor
      MDx a.s.</span></font></font></b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">,
      and regard it as strictly confidential. This means that in no case
      will this information be disclosed to a third party, except for
      companies that provide for payment transactions (such as banks,
      members of BioVendor Group or Bioxsys s.r.o. as Licensor). However,
      this is always carried out entirely within the limits stipulated by
      personal information protection laws.</span></font></font></font></font></p>
      <p align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">BioVendor
      MDx a.s.</span></font></font></b><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt"><span lang="en-GB">,
      informs the public and reliable entities about its activity, in the
      first place by means of website visit statistics and some Customer
      information. This information never contains any specific data about
      individual clients.</span></font></font></font></font></p>
      <h2 lang="en-GB" class="western" align="justify"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Cookies</font></font></h2>
      <p lang="en-GB" align="justify" style="margin-top: 0.18cm; margin-bottom: 0.18cm; line-height: 100%">
      <font face="Arial, sans-serif">Cookies are not used.</font></p>
      <h2 lang="en-GB" class="western" align="justify"><font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Data
      protection</font></font></h2>
      <h2 lang="en-GB" class="western" align="justify" style="font-weight: normal">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Data
      are secure uploaded via Hypertext Transfer Protocol Secure (HTTPS) to
      data centre located in Czech Republic.</font></font></h2>
      <h2 lang="en-GB" class="western" align="justify" style="font-weight: normal">
      <font face="Arial, sans-serif"><font size="2" style="font-size: 11pt">Data
      are long-time store and protected behind the firewall with no access
      from world wide web.</font></font></h2>
      <p lang="en-GB" align="justify" style="margin-bottom: 0.28cm; line-height: 107%">
      <br/>
      <br/>

      </p>
      <div title="footer">
        <table width="604" cellpadding="7" cellspacing="0">
          <col width="187"/>

          <col width="187"/>

          <col width="187"/>

          <tr valign="top">
            <td width="187" style="border: none; padding: 0cm"><p style="margin-left: -0.2cm">
              <br/>

              </p>
            </td>
            <td width="187" style="border: none; padding: 0cm"><p align="center">
              <br/>

              </p>
            </td>
            <td width="187" style="border: none; padding: 0cm"><p align="right" style="margin-right: -0.2cm">
              <br/>

              </p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom: 0cm; line-height: 100%"><br/>

        </p>
      </div>
        `,

        hpo: [],
        l_hpo: [],
        l_icd11: [],
        l_gene: [],
        ss_gene: "",
        l_consequences: [],
        l_features: [],
        l_clinvars: [],
        d_clinvars: {},
        l_clinvar_dns: [],
        l_ethnic: [],
        l_countries: [],
        l_exam_types: [],
        l_iclasses: [],
        d_acmg_sigs: [],
        l_seq_technology: [],
        d_a_cols: {},
        ff_name: "",
        ff_description: "",
        d_pseudopanels: {},
        acmg_data: [],
        freq_data: [],
        predictors_data: [],
        d_filter: {},
        d_cufis: {},
        l_cufis: [],
        cufi_selected: 1,
        polyt_data: [],
        cnv_data: [],

        exam_visualisation: true,

        cufi_yes: true,

        cufi: {
            id_cufi: null,
            id_workflow: 0,
            cufi_name: "",
            cufi_note: "",
            cufi_default: false,
            cufi_lock: false,
            cufi_yes: false,
            full_yes: false,
            read_depth: 0,
            freq_vcf: 0.0,
            genotype: [],
            freq_project: 0,
            freq_subject: 0,
            impacts: [],
            consequences: [],
            clinvar: [],
            gnomad_max_af: 0.0000,
            abq: 0,
            genes: [],
            my_iclasses: [],
            syn_vars: true,
            fil_hompol: true,
            fil_repeats: true,
            fil_other: true,
            chb_read_depth: false,
            chb_freq_vcf: false,
            chb_freq_project: false,
            chb_freq_subject: false,
            chb_gnomad_max_af: false,
            chb_abq: false,
            mnv_switch: true,
            my_pseudopanels: [],
        },

        patient_card_default: {
            id_patient: 0,
            hash: "",
            surname: "",
            name: "",
            title: "",
            pid: "",
            birth_date: "",
            gender: false,
            ethnic: "",
            nationality: "",
            insurance: "",
            phone: "",
            email: "",
            street: "",
            city: "",
            zip: "",
            country: "",
            note: "",
            icd11: [],
            hpo: [],
            diagnose1: "",
            diagnose2: "",
            privat_note: "",
            height: 0,
            weight: 0,
            bmi: 0,
            smoker: false,
            drinker: false,
            pressure: 0,

        },

        patient_card: {
            id_patient: 0,
            hash: "",
            surname: "",
            name: "",
            title: "",
            pid: "",
            birth_date: "",
            gender: false,
            ethnic: "",
            nationality: "",
            insurance: "",
            phone: "",
            email: "",
            street: "",
            city: "",
            zip: "",
            country: "",
            note: "",
            icd11: [],
            hpo: [],
            diagnose1: "",
            diagnose2: "",
            privat_note: "",
            height: 0,
            weight: 0,
            bmi: 0,
            smoker: false,
            drinker: false,
            pressure: 0,

        },
        patient_card_disabled: false,

        projects: [],
        id_project: 0,
        id_workflow: 0,
        id_project_detail: 0,
        examinations: [],
        examinations_patient: [],
        examinations_ext_patient: [],
        id_examination: 0,
        id_examination_detail: 0,
        selected_exams: [],
        vars: [],
        id_var: 0,
        glovars: [],
        id_glovar: 0,
        vars_selected_tab: "b",
        slider_value: 1000,
        igv_switch: false,
        igv_on: false,
        search_vars_by_select: "",
        search_vars_by_value: "",
        var_page: 1,
        vars_order: 1,
        vars_selected: null,
        f_filters: [],
        u_filters: [],
        c_filter: {},
        workflows: [],
        patients: [],
        patient: {},
        id_patient: 0,
        id_patient_card: 0,

        freq_proj: [],
        freq_wf: [],


        project_files_data: [],
        project_files_others: [],
        qc_redirect: "",
        transcripts: [],

        collection: "",
        f_q: [],
        f_m: [],
        f_o: [],
        f_v: [],
        f_b: [],
        f_c: [],
        f_gf: [],
        f_gf_qc: [],
        f_gf_r: [],

        u_f_q: [],
        u_f_m: [],
        u_f_o: [],
        u_f_v: [],
        u_f_b: [],
        u_f_c: [],
        u_f_gf: [],
        u_f_gf_qc: [],
        u_f_gf_r: [],

        qc_picard_hs: {},
        qc_picard_amp: {},
        qc_picard_amp_target: [],
        chart_bar_a: [],

        pagination_v: {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [10, 20, 50, 100, 200, ],
            total: 0
        },
        sorting_v: {
            prop: "clinvar_weight",
            ord: "ascending"
        },
        loading_v: false,
        loading_e: false,
        loading_full: false,
        loading_cnv: false,

        exam_det: {
            id_examination: 0,
            id_project: 0,
            sample_id: "",
            int_sample_id: "",
            personal_id: "",
            sample_type: "",
            sample_taken: "",
            sample_received: "",
            indication: "",
            description: "",
            indication_for_testing: "",
            result_interpretation: "",
            exam_note: "",
            icd_note: "",
            hpo_note: "",
            sample_name_and_last_name: "",
            birth_date: "",
            gender: "",
            referring_hospital: "",
            referred_by: "",
            hpo: [],
            icd11: [],
            id_analysis_type: 0,
        }

    },

    getters: {
        // g_k_hpo: (state, getters) => {
        //     return Object.keys(state.hpo)
        // },

        g_gene: (state, getters) => {
            if (state.ss_gene > "") {
                return state.l_gene.filter(one => (one.includes(state.ss_gene.toUpperCase())));
            } else {
                return [];
            }
        },


        g_iclasses: (state, getters) => (my_id_class) => {
            let _my_id_class = parseInt(my_id_class);
            if (_my_id_class >= 0) {
                return state.l_iclasses.filter(one => (one.id_iclass === _my_id_class))[0]["label"];
            } else {
                return "---"
            }
        },


        g_iclasses_style: (state, getters) => (my_id_class) => {
            let _my_id_class = parseInt(my_id_class);
            if (_my_id_class >= 0) {

                return state.l_iclasses.filter(one => (one.id_iclass === _my_id_class))[0]["style"];
            } else {
                return ""
            }
        },


        g_project: (state, getters) => {
            if (state.id_project > 0) {
                return state.projects.filter(one => (one.id_project === state.id_project))[0];
            } else {
                return {};
            }
        },


        g_examination_selected: (state, getters) => {
            if (state.id_examination > 0) {
                return state.examinations.filter(one => (one.id_examination === state.id_examination))[0];
            } else {
                return {};
            }
        },


        g_examination: (state, getters) => {
            if (state.id_examination_detail > 0) {
                return state.examinations.filter(one => (one.id_examination === state.id_examination_detail))[0];
            } else {
                return {};
            }
        },


        g_examination_export: (state, getters) => {
            if (state.id_examination > 0) {
                return state.examinations.filter(one => (one.id_examination === state.id_examination))[0];
            } else {
                return {};
            }
        },


        g_examination_vars: (state, getters) => {
            if (state.id_examination > 0) {
                let myvars = [];
                state.vars.forEach(element => myvars.push(element.id_var));
                return myvars;
            } else {
                return [];
            }
        },


        g_examination_for_submit: (state, getters) => {
            return state.examinations.filter(one => (one.status === 1));
        },


        g_workflows: (state, getters) => {
            return state.workflows.filter(one => (one.app === state.rapp));
        },


        g_upload_file: (state, getters) => {
            if (state.current_url.hostname === "localhost") {
                return state.current_url.protocol + "//" + state.current_url.hostname + ":8101" + "/uploadfile";
            } else {
                return state.current_url.protocol + "//" + state.current_url.hostname + "/ra/uploadfile";
            }
        },


        g_upload_data_file: (state, getters) => {
            if (state.current_url.hostname === "localhost") {
                return state.current_url.protocol + "//" + state.current_url.hostname + ":8101" + "/uploadfile";
            } else {
                return state.current_url.protocol + "//" + state.current_url.hostname + "/ra/uploadfile";
            }
        },


        g_upload_csvfile: (state, getters) => {
            if (state.current_url.hostname === "localhost") {
                return state.current_url.protocol + "//" + state.current_url.hostname + ":8101" + "/uploadcsvfile";
            } else {
                return state.current_url.protocol + "//" + state.current_url.hostname + "/ra/uploadcsvfile";
            }
        },


        g_upload_xlsx: (state, getters) => {
            if (state.current_url.hostname === "localhost") {
                return state.current_url.protocol + "//" + state.current_url.hostname + ":8101" + "/uploadxlsx";
            } else {
                return state.current_url.protocol + "//" + state.current_url.hostname + "/ra/uploadxlsx";
            }
        },


        g_upload_file_pr: (state, getters) => {
            if (state.current_url.hostname === "localhost") {
                return state.current_url.protocol + "//" + state.current_url.hostname + ":8199" + "/uploadfile";
            } else {
                return state.current_url.protocol + "//" + state.current_url.hostname + "/ra/uploadfileprivat";
            }
        },


        g_patient_card: (state, getters) => {
            if (state.id_examination_detail > 0) {
                return state.examinations.filter(one => (one.id_examination === state.id_examination_detail))[0];
            } else {
                return {};
            }
        },


        g_exam_hpo: (state, getters) => (exam_hpo, hpo_query) => {
            if (exam_hpo.length > 0 && hpo_query.length > 2) {
                let sel = state.l_hpo.filter(one => (exam_hpo.includes(one["hpo_id"])));
                let que = state.l_hpo.filter(one => (one["hpo_term_name_lower"].includes(hpo_query)));
                return sel.concat(que.filter((item) => sel.indexOf(item) < 0));
            } else if (exam_hpo.length > 0 ) {
                return state.l_hpo.filter(one => (exam_hpo.includes(one["hpo_id"])));
            } else if ( hpo_query.length > 2) {
                return state.l_hpo.filter(one => (one["hpo_term_name_lower"].includes(hpo_query)));;
            } else {
                return [];
            }
        },


        g_exam_icd11: (state, getters) => (exam_icd11, icd11_query) => {
            if (exam_icd11.length > 0 && icd11_query.length > 2) {
                let sel = state.l_icd11.filter(one => (exam_icd11.includes(one["id_icd11"])));
                let que = state.l_icd11.filter(one => (one["icd11_search_lower"].includes(icd11_query)));
                return sel.concat(que.filter((item) => sel.indexOf(item) < 0));
            } else if (exam_icd11.length > 0 ) {
                return state.l_icd11.filter(one => (exam_icd11.includes(one["id_icd11"])));
            } else if ( icd11_query.length > 2) {
                return state.l_icd11.filter(one => (one["icd11_search_lower"].includes(icd11_query)));;
            } else {
                return [];
            }
        },


        g_cufis: (state, getters) => {
            if (state.id_workflow > 0) {
                return state.l_cufis.filter(one => (one.id_workflow === state.id_workflow));
            } else {
                return []
            }
        },

        g_predictors_data: (state, getters)  => (category) => {
            return state.predictors_data.filter(one => (one.pred_category === category));
        },



    },

    mutations: {
        m_modal_login(state, value) {
            state.modal_login = value;
        },

        m_modal_reg(state, value) {
            state.modal_reg = value;
        },

        m_modal_forgot(state, value) {
            state.modal_forgot = value;
        },

        m_modal_passwd(state, value) {
            state.modal_passwd = value;
        },

        m_modal_info(state, value) {
            state.modal_info = value;
        },

        m_modal_terms(state, value) {
            state.modal_terms = value;
        },

        m_apps(state, value) {
            for (var member in state.apps) delete state.apps[member];
            // for (var newmember in value) {
            //     console.log(newmember);
            //     console.log(value[newmember]);
            //     state.apps[newmember] = value[newmember];
            // }
            Object.assign(state.apps, value);
        },

        m_app(state, value) {
            state.app = value;
        },


        m_rapp(state, value) {
            state.rapp = value;
        },


        m_app_version(state, value) {
            state.app_version = value;
        },


        m_ws(state, value) {
            state.ws = value;
        },


        m_modal_info_text(state, value) {
            state.modal_info_text = value;
            if (state.modal_info_text > '') {
                state.modal_info = true
            } else {
                state.modal_info = false
            }
        },


        m_modal_interpretation(state, value) {
            state.modal_interpretation = value;
        },


        m_modal_transcripts(state, value) {
            state.modal_transcripts = value;
        },


        m_modal_patient(state, value) {
            state.modal_patient = value;
        },


        m_modal_exam_ext_detail(state, value) {
            state.modal_exam_ext_detail = value;
        },


        m_vars_tab_name(state, value) {
            state.vars_tab_name = value;
        },


        m_token(state, value) {
            state.token = value;
        },


        m_id_user(state, value) {
            state.id_user = value;
        },


        m_id_biorole(state, value) {
          state.id_biorole = value;
        },


        m_login(state, { email, password }) {

        },


        m_join_subject(state, value) {
            state.roles = value;
        },


        m_subject(state, value) {
            state.subject = value;
        },


        m_user(state, value) {
            state.user = value;
        },


        m_privat_be(state, value) {
            state.privat_be = value;
        },


        m_current_url(state, value) {
            state.current_url = value;
        },


        m_application(state, value) {
            state.application = value;
        },


        m_subversion(state, value) {
            state.subversion = value;
        },


        m_hpo(state, value) {
            state.hpo = value;
        },


        m_l_hpo(state, value) {
            state.l_hpo = value;
        },

        m_l_icd11(state, value) {
            state.l_icd11 = value;
        },

        m_l_ethnic(state, value) {
            state.l_ethnic = value;
        },

        m_l_countries(state, value) {
            state.l_countries = value;
        },

        m_l_exam_types(state, value) {
            state.l_exam_types = value;
        },

        m_l_iclasses(state, value) {
            state.l_iclasses = value;
        },


        m_d_acmg_sigs(state, value) {
          state.d_acmg_sigs = value;
        },

        m_l_seq_technology(state, value) {
            state.l_seq_technology = value;
        },

        m_l_gene(state, value) {
            state.l_gene = value;
        },

        m_ss_gene(state, value) {
            state.ss_gene = value;
        },

        m_l_consequences(state, value) {
            state.l_consequences = value;
            // state.l_consequences.unshift("");
        },

        m_l_features(state, value) {
            state.l_features = value;
            // state.l_features.unshift("");
        },

        m_l_clinvars(state, value) {
            state.l_clinvars = value;
            // state.l_clinvars.unshift("");
        },

        m_d_clinvars(state, value) {
            state.d_clinvars = value;
        },

        m_l_clinvar_dns(state, value) {
            state.l_clinvar_dns = value;
            // state.l_clinvar_dns.unshift("");
        },

        m_d_a_cols(state, value) {
            state.d_a_cols = value;
        },

        m_qc_picard_amp(state, value) {
            state.qc_picard_amp = value;

        },

        m_qc_picard_amp_target(state, value) {
            state.qc_picard_amp_target = value;

        },

        m_qc_picard_hs(state, value) {
            state.qc_picard_hs = value;

        },

        m_d_pseudopanels(state, value) {
            state.d_pseudopanels = value;

        },

        // m_qc_b(state, value) {
        //     state.qc_b = value;
        // },

        m_chart_bar_a(state, value) {
            state.chart_bar_a = value;
        },



        m_cufi_setup(state, wf) {
            Object.assign(state.cufi, state.cufis[wf]);
        },


        m_cufi_load(state) {
            if (state.cufi_selected > 0) {
                Object.assign(state.cufi, state.d_cufis[state.cufi_selected]);
            } else {
                Object.assign(state.cufi, state.d_cufis[1]);
            }
        },


        m_cufi_default_my_iclasses(state, value) {
            state.cufi_default["my_iclasses"] = value;
        },

        m_cufi(state, { mykey, value }) {
            state.cufi[mykey] = value;
        },

        m_cufi_my_iclasses(state, value) {
            state.cufi["my_iclasses"] = value;
        },

        m_cufi_yes(state, value) {
            state.cufi_yes = value;
        },

        m_cufi_selected(state, value) {
            state.cufi_selected = value;
        },

        m_ff_name(state, value) {
            state.ff_name = value;
        },

        m_ff_description(state, value) {
            state.ff_description = value;
        },

        m_exam_visualisation(state, value) {
            state.exam_visualisation = value;
        },


        m_projects(state, value) {
            state.projects = value;
        },

        m_projects_refresh(state, value) {
            value.forEach(function(member) {
                state.projects.forEach(function(project) {
                    if ((project.id_project === member.id_project) && (project.status !== member.status)) {
                        project.status = member.status;
                    }
                })
            })
        },

        m_id_project(state, value) {
            state.id_project = value;
            state.u_f_q = [];
            state.u_f_m = [];
            state.u_f_o = [];
            state.u_f_v = [];
            state.u_f_b = [];
            state.u_f_c = [];
            state.u_f_gf = [];
            state.u_f_gf_qc = [];
            state.u_f_gf_r = [];

            state.f_q = [];
            state.f_m = [];
            state.f_o = [];
            state.f_v = [];
            state.f_b = [];
            state.f_c = [];
            state.f_gf = [];
            state.f_gf_qc = [];
            state.f_gf_r = [];
        },

        m_id_project_detail(state, value) {
            state.id_project_detail = value;
            state.u_f_q = [];
            state.u_f_m = [];
            state.u_f_o = [];
            state.u_f_v = [];
            state.u_f_b = [];
            state.u_f_c = [];
            state.u_f_gf = [];
            state.u_f_gf_qc = [];
            state.u_f_gf_r = [];

            state.f_q = [];
            state.f_m = [];
            state.f_o = [];
            state.f_v = [];
            state.f_b = [];
            state.f_c = [];
            state.f_gf = [];
            state.f_gf_qc = [];
            state.f_gf_r = [];
        },

        m_project(state, value) {
            state.project = value;
        },

        m_modal_new_project(state, value) {
            state.modal_new_project = value;
        },

        m_modal_project_detail(state, value) {
            state.modal_project_detail = value;
        },

        m_modal_project_stat(state, value) {
            state.modal_project_stat = value;
        },

        m_id_workflow(state, value) {
            state.id_workflow = value;
        },

        m_examinations(state, value) {
            state.examinations = value;
        },

        m_examinations_refresh(state, value) {
            value.forEach(function(member) {
                state.examinations.forEach(function(examination) {
                    if ((examination.id_examination === member.id_examination)) {
                        examination.status = member.status;
                        examination.sample_id = member.sample_id;
                        examination.total_reads = member.total_reads;
                        examination.median_target_coverage = member.median_target_coverage;
                        examination.pct_exc_dupe = member.pct_exc_dupe;
                        examination.pct_target_bases_30x = member.pct_target_bases_30x;
                        examination.pct_target_bases_100x = member.pct_target_bases_100x;
                        examination.statim = member.statim;
                        examination.published = member.published;
                        examination.report = member.report;
                    }
                })
            })
        },

        m_id_examination(state, value) {
            state.id_examination = value;
        },

        m_selected_exams(state, value) {
            state.selected_exams = value;
        },

        m_modal_exam_qc(state, value) {
            state.modal_exam_qc = value;
        },

        m_modal_exam_msi_tmb(state, value) {
          state.modal_exam_msi_tmb = value;
        },

        m_modal_acmg(state, value) {
          state.modal_acmg = value;
        },

        m_modal_freq(state, value) {
          state.modal_freq = value;
        },

        m_modal_predictors(state, value) {
          state.modal_predictors = value;
        },

        m_modal_hpo_terms(state, value) {
          state.modal_hpo_terms = value;
        },

        m_modal_exam_detail(state, value) {
            state.modal_exam_detail = value;
        },

        m_modal_exam_files(state, value) {
            state.modal_exam_files = value;
        },

        m_cufi_my_pseudopanels(state, value) {
            state.cufi.my_pseudopanels = value;
        },

        m_acmg_data(state, value) {
            state.acmg_data = value;
        },

        m_freq_data(state, value) {
            state.freq_data = value;
        },

        m_predictors_data(state, value) {
            state.predictors_data = value;
        },

        m_d_filter(state, value) {
            state.d_filter = value;
        },

        m_d_cufis(state, value) {
            state.d_cufis = value;
        },

        m_l_cufis(state, value) {
            state.l_cufis = value;
        },

        m_polyt_data(state, value) {
            state.polyt_data = value;
        },

        m_cnv_data(state, value) {
            state.cnv_data = value;
        },

        m_id_examination_detail(state, value) {
            state.id_examination_detail = value;
        },

        m_examinations_patient(state, value) {
            state.examinations_patient = value;
        },

        m_examinations_ext_patient(state, value) {
            state.examinations_ext_patient = value;
        },


        m_exam_det(state, { mykey, value }) {
            state.exam_det[mykey] = value;
        },

        m_examination(state, value ) {
            for (var member in state.exam_det) state.exam_det[member] = value[member];
            // Object.assign(state.exam_det, value);
        },

        m_vars(state, value) {
            state.vars = value;
        },

        m_vars_selected_tab(state, value) {
            state.vars_selected_tab = value;
        },

        m_freq_proj(state, value) {
            state.freq_proj = value;

            value.forEach(function(member) {
                state.vars.forEach(function(one) {
                    if (one.id_var === member.id_var) {
                        one.f_proj = member.f_proj;
                        one.e_proj = member.e_proj;
                    }
                })
            });
            // console.log(value);
        },

        m_freq_wf(state, value) {
            state.freq_wf = value;

            value.forEach(function(member) {
                state.vars.forEach(function(one) {
                    if ((one.id_var === member.id_var) && (member.id_analysis_type === 1)) {
                        one.f_wf1 = member.f_w_count;
                        one.e_wf1 = member.w_count;
                    } else if ((one.id_var === member.id_var) && (member.id_analysis_type === 2)) {
                        one.f_wf2 = member.f_w_count;
                        one.e_wf2 = member.w_count;
                    }
                })
            });
            // console.log(value);
        },

        m_vars_order(state, value) {
            state.vars_order = value;
        },

        m_vars_selected(state, value) {
            state.vars_selected = value;
        },

        m_glovars(state, value) {
            state.glovars = value;
        },

        m_slider_value(state, value) {
            state.slider_value = value;
        },

        m_igv_switch(state, value) {
            state.igv_switch = value;
        },

        m_igv_on(state, value) {
            state.igv_on = value;
        },

        m_search_vars_by_select(state, value) {
            state.search_vars_by_select = value;
        },

        m_search_vars_by_value(state, value) {
            state.search_vars_by_value = value;
        },

        m_var_page(state, value) {
            state.var_page = value;
        },

        m_f_filters(state, value) {
            state.f_filters = value;
        },

        m_u_filters(state, value) {
            state.u_filters = value;
        },

        m_workflows(state, value) {
            state.workflows = value;
        },

        m_patients(state, value) {
            state.patients = value;
        },

        m_patient(state, value) {
            state.patient = value;
        },

        m_id_patient(state, value) {
            state.id_patient = value;
        },

        m_id_patient_card(state, value) {
            state.id_patient_card = value;
        },


        m_patient_card(state, value) {
            state.patient_card = value;
        },

        m_patient_card_hpo_add(state, value) {
            let xxx = [];
            state.patient_card.hpo.forEach(one => xxx.push(one.hpo_id));
            if (xxx.length > 0 && xxx.includes(value.hpo_id)) {

            } else {
                state.patient_card.hpo.push(value);
            }
        },

        m_patient_card_hpo_del(state, value) {
            state.patient_card.hpo = state.patient_card.hpo.filter(member => member.hpo_id != value);
        },

        m_patient_card_icd11_add(state, value) {
            let xxx = [];
            state.patient_card.icd11.forEach(one => xxx.push(one.id_icd11));
            if (xxx.length > 0 && xxx.includes(value.id_icd11)) {

            } else {
                state.patient_card.icd11.push(value);
            }
        },

        m_patient_card_icd11_del(state, value) {
            state.patient_card.icd11 = state.patient_card.icd11.filter(member => member.id_icd11 != value);
        },


        m_anots(state, value) {
            state.anots = value;
        },

        m_project_files_data(state, value) {
            state.project_files_data = value;
        },

        m_project_files_others(state, value) {
            state.project_files_others = value;
        },

        m_qc_redirect(state, value) {
            state.qc_redirect = value;
        },

        m_transcripts(state, value) {
            state.transcripts = value;
        },

        m_f_q(state, value) {
            state.f_q = value;
        },

        m_f_m(state, value) {
            state.f_m = value;
        },

        m_f_o(state, value) {
            state.f_o = value;
        },

        m_f_v(state, value) {
            state.f_v = value;
        },

        m_f_b(state, value) {
            state.f_b = value;
        },

        m_f_c(state, value) {
            state.f_c = value;
        },

        m_f_gf(state, value) {
            state.f_gf = value;
        },

        m_f_gf_qc(state, value) {
            state.f_gf_qc = value;
        },

        m_f_gf_r(state, value) {
            state.f_gf_r = value;
        },

        m_collection(state, value) {
            state.collection = value;
        },


    },

    actions: {
        a_notify(context, payload) {
            notifier.$notify(payload);
        },

        load_cviceni_zdroje({ commit }) {
            axios.get(process.env.VUE_APP_BE + '/')
                .then(response => {
                    console.log(response.data["cviceni_zdroje"]);
                    commit('set_cviceni_zdroje', response.data["cviceni_zdroje"])


                }, error => {
                    console.log(error);
                });
        },

        a_login({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/login', { email: payload.email, password: payload.password }, { withCredentials: true, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_token', response.data["token"]);
                    if (response.data["token"] > "") {
                        // localStorage.setItem('current_user', response.data["current_user"]);
                        localStorage.setItem('token', response.data["token"]);
                        commit('m_modal_login', false);
                        commit('m_user', response.data["user"]);
                        store.dispatch('a_my_role', state.user.last_role);
                    } else {
                        commit('m_modal_login', false);
                        commit('m_modal_info_text', "Please provide valid login credentials.");
                    }
                }, error => {
                    console.log(error);
                });

        },

        a_logout({ state, commit, getters } ) {
            axios.get(process.env.VUE_APP_BE + '/logout', { headers: { 'Authorization': state.token } }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    // commit('m_id_user', response.data["current_user"]);
                    commit('m_token', "");
                    // localStorage.removeItem("current_user");
                    localStorage.removeItem("token");
                }, error => {
                    console.log(error);
                });

            // commit('m_modal_login', false);
            router.push("/");


        },


        a_reg({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/registration', payload, { withCredentials: true, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data.status === 1) {
                        commit('m_modal_reg', false);
                        commit('m_modal_info_text', "Thank You For Your Registration. Your registration is currently being processed. You will receive an email shortly to finish your registration.");

                    } else {
                        commit('m_modal_reg', false);
                    }
                }, error => {
                    console.log(error);
                });

        },


        a_forgot({ state, commit, getters }, payload) {
            axios.patch(process.env.VUE_APP_BE + '/login', payload, { withCredentials: true, headers: { 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    console.log(response.data);
                    commit('m_modal_forgot', false);
                    // router.push("/welcome");

                }, error => {
                    console.log(error);
                });

        },


        a_passwd({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/change_password', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_modal_passwd', false);
                    if ( response.data.changed === 1 ) {
                        this.dispatch('a_notify', {
                            message: 'Password has changed.',
                        }, { root: true });

                    } else {
                        this.dispatch('a_notify', {
                            message: "Password hasn't changed.",
                        }, { root: true });
                    }
                }, error => {
                    console.log(error);
                });
        },


        a_join_subject({ state, commit, getters } ) {
            axios.get(process.env.VUE_APP_BE + '/join_subject', { headers: { 'Authorization': state.token } }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_join_subject', response.data["roles"]);
                    router.push("/join_subject");
                }, error => {
                    console.log(error);
                });
            console.log(state.apps)

        },


        a_load_profile({ state, commit, getters } ) {
            axios.get(process.env.VUE_APP_BE + '/load_profile', { headers: { 'Authorization': state.token } }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_subject', response.data["subject"]);
                    commit('m_user', response.data["user"]);
                    commit('m_apps', response.data["apps"]);
                    commit('m_id_biorole', response.data["id_biorole"]);


                    if (response.data["token"] === "") {
                        commit('m_token', "");
                        localStorage.removeItem("token");
                        self.go();
                    }

                    // commit('m_workflows', response.data["workflows"]);

                    if (state.current_url.hostname !== "localhost") {
                        // commit('m_privat_be', response.data["subject"]["privat_api"]);
                        commit('m_privat_be', "/pv");
                    }

                }, error => {
                    console.log(error);
                });
        },


        a_my_role({ state, commit, getters }, id_role) {
            axios.post(process.env.VUE_APP_BE + '/join_subject', { id_role: id_role }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_projects', []);
                    commit('m_id_project', 0);
                    commit('m_examinations', []);
                    commit('m_id_examination', 0);
                    commit('m_vars', []);
                    commit('m_subject', response.data["subject"]);
                    if ((this.current_url) && (this.current_url.hostname === "localhost")) {
                        commit('m_privat_be', "http://localhost:8199/");
                    } else {
                        // commit('m_privat_be', response.data["subject"]["privat_api"]);
                        commit('m_privat_be', "/pv");
                    };

                    commit('m_apps', response.data["apps"]);

                    store.dispatch('a_notify', {
                        message: "You've joined into " + response.data["subject"]["subject"],
                    });
                    router.push("/");
                    router.go(0);

                    // commit('m_join_subject', response.data["roles"]);
                    // router.push("/welcome");
                }, error => {
                    console.log(error);
                });
        },


        a_test({ state, commit, getters } ) {
            axios.post(process.env.VUE_APP_BE + '/test', {}, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    console.log(response.data);
                }, error => {
                    console.log(error);
                });
            console.log("Logout");
        },


        a_filter_save({ state }) {
            axios.post(process.env.VUE_APP_BE + '/filter_save', { cufi: state.cufi, name: state.ff_name, description: state.ff_description }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    console.log(response.data);
                }, error => {
                    console.log(error);
                });
        },


        a_patients({ state, commit, getters } ) {
            axios.get(process.env.VUE_APP_BE + '/patients', { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_patients', response.data["patients"]);
                }, error => {
                    console.log(error);
                });
        },


        a_patient({ state, commit, getters }, myhash) {
            axios.get(process.env.VUE_APP_BE + '/patient', { withCredentials: true, params: { "myhash": myhash }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_patient', response.data["patient"]);

                }, error => {
                    console.log(error);
                });
        },


        a_patient_card({ state, commit }) {
            axios.post(process.env.VUE_APP_BE + '/patient_card', { "patient_card": state.patient_card }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_patient_card', response.data["patient_card"]);
                }, error => {
                    console.log(error);
                });
        },


        a_patient_card_load({ state, commit, getters }) {
            axios.get(process.env.VUE_APP_BE + '/patient_card', { withCredentials: true, params: { "id_patient": state.id_patient }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_patient_card', response.data["patient_card"]);
                }, error => {
                    console.log(error);
                });
        },


        a_patient_card_reset({ state, commit, getters }) {
            commit('m_patient_card', state.patient_card_default);
        },


        a_projects({ state, commit }, id_project = 0) {
            axios.get(process.env.VUE_APP_BE + '/projects', { withCredentials: true, params: { "id_project": id_project }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (id_project === 0) {
                        commit('m_projects', response.data["projects"]);
                    } else {
                        commit('m_projects_refresh', response.data["projects"]);
                    }
                }, error => {
                    console.log(error);
                });
        },


        a_project_new({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/projects', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_projects', response.data["projects"]);
                    this.dispatch('a_notify', {
                        message: 'New project has been created.',
                    }, { root: true });
                }, error => {
                    console.log(error);
                });
        },


        a_project_update({ state, commit, getters }, payload) {
            axios.put(process.env.VUE_APP_BE + '/projects', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_projects', response.data["projects"]);
                    this.dispatch('a_notify', {
                        message: 'Project has been updated.',
                    }, { root: true });
                }, error => {
                    console.log(error);
                });
        },


        a_project_delete({ state, commit, getters }, payload) {
            axios.delete(process.env.VUE_APP_BE + '/projects', { data: payload, withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data["status"] === 1) {
                        commit('m_projects', response.data["projects"]);
                        this.dispatch('a_notify', {
                            message: 'Project has been removed.',
                        }, { root: true });
                    } else {
                        this.dispatch('a_notify', {
                            message: 'Project can not be removed.',
                        }, { root: true });
                    }
                }, error => {
                    console.log(error);
                });
        },


        a_project_renew({ state, commit, getters }, payload) {
            axios.patch(process.env.VUE_APP_BE + '/projects', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_projects', response.data["projects"]);
                    this.dispatch('a_notify', {
                        message: 'Project has been updated.',
                    }, { root: true });
                }, error => {
                    console.log(error);
                });
        },


        a_examinations({ state, commit, getters }, payload) {
            state.loading_e = true;
            axios.post(process.env.VUE_APP_BE + '/examinations', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_examinations', response.data["examinations"]);
                    // router.push("/join_subject");
                }, error => {
                    console.log(error);
                })
                .finally(() => {
                    state.loading_e = false;
                });
        },


        a_examinations_refresh({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/examinations', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_examinations_refresh', response.data["examinations"]);
                    // router.push("/join_subject");
                }, error => {
                    console.log(error);
                });
        },


        a_examination({ state, commit }, id_examination ) {
            axios.get(process.env.VUE_APP_BE + '/examination', { withCredentials: true, params: { "id_examination": id_examination }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_examination', response.data["examination"]);
                    // router.push("/join_subject");
                }, error => {
                    console.log(error);
                });
        },


        a_examination_validate({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/examination_validate', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data["status"] === 3) {
                        this.dispatch('a_examinations', { "id_project": state.id_project });
                        this.dispatch('a_notify', {
                            message: 'Validated, the process will be started.',
                        }, { root: true });
                    } else if (response.data["status"] === 12) {
                        this.dispatch('a_examinations', { "id_project": state.id_project });
                        this.dispatch('a_notify', {
                            message: 'Validated, the examination will load variants into database now.',
                        }, { root: true });
                    } else {
                        this.dispatch('a_notify', {
                            message: 'Missing FASTQ file(s), the process is stopped.',
                        }, { root: true });
                    }
                }, error => {
                    console.log(error);
                });
        },


        a_examination_delete({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/examination_delete', { data: payload }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data["status"] === 1) {
                        commit('m_examinations', response.data["examinations"]);
                        this.dispatch('a_notify', {
                            message: 'Sample information has been removed.',
                        }, { root: true });
                    } else {
                        this.dispatch('a_notify', {
                            message: 'Sample information can not be removed.',
                        }, { root: true });
                    }
                }, error => {
                    console.log(error);
                });
        },




        a_examination_update({ state, commit } ) {
            axios.put(process.env.VUE_APP_BE + '/examination', state.exam_det, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    console.log(state.id_project);
                    this.dispatch('a_examinations_refresh', { "id_project": state.id_project });

                    this.dispatch('a_notify', {
                        message: 'Sample information has been updated.',
                    }, { root: true });

                }, error => {
                    console.log(error);
                });
        },


        a_examinations_patient({ state, commit, getters } ) {
            axios.post(process.env.VUE_APP_BE + '/examinations_patient', { "id_patient": state.id_patient }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_examinations_patient', response.data["examinations_patient"]);
                }, error => {
                    console.log(error);
                });
        },


        a_examinations_ext_patient({ state, commit, getters } ) {
            axios.post(process.env.VUE_APP_BE + '/examinations_ext_patient', { "id_patient": state.id_patient }, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_examinations_ext_patient', response.data["examinations_ext_patient"]);
                }, error => {
                    console.log(error);
                });
        },



        a_examination_gf_report({ state, commit }, payload ) {
            axios.patch(process.env.VUE_APP_BE + '/examination', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    this.dispatch('a_examinations_refresh', { "id_project": state.id_project });

                    this.dispatch('a_notify', {
                        message: 'Sample is ready for create report.',
                    }, { root: true });

                }, error => {
                    console.log(error);
                });
        },


        a_varsall({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/varsall', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_vars', response.data["vars"]);
                }, error => {
                    console.log(error);
                });
        },


        a_vars({ state, commit, getters }, payload) {
            if (payload["export"] > 0) {
                axios.post(process.env.VUE_APP_BE + '/vars', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/octet-stream', }, responseType: 'blob', })
                    .then(response => {
                        var blob = new Blob([response.data]);
                        var filename = "variants_" + payload["sample_id"] + ".xlsx"
                        FileSaver.saveAs(blob, filename);
                    }, error => {
                        console.log("No Variants to export.");

                    });
            } else {
                state.loading_v = true;
                state.loading_cnv = true;
                axios.post(process.env.VUE_APP_BE + '/vars', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                    .then(response => {
                        commit('m_vars', response.data["vars"]);
                        state.pagination_v.total = response.data["total"];
                        commit('m_polyt_data', response.data["polyt_data"]);
                        commit('m_cnv_data', response.data["cnv_data"]);
                    }, error => {
                        console.log(error);
                    })
                    .finally(() => {
                        state.loading_v = false;
                        state.loading_cnv = false;
                    });
            }

        },


        a_glovars({ state, commit, getters }, payload) {
            if (payload["export"] > 0) {
                axios.post(process.env.VUE_APP_BE + '/vars', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/octet-stream', }, responseType: 'blob', })
                    .then(response => {
                        var blob = new Blob([response.data]);
                        var filename = "variants_" + payload["sample_id"] + ".xlsx"
                        FileSaver.saveAs(blob, filename);
                    }, error => {
                        console.log("No Variants to export.");

                    });
            } else {
                state.loading_v = true;
                state.loading_cnv = true;
                axios.post(process.env.VUE_APP_BE + '/vars', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                    .then(response => {
                        commit('m_vars', response.data["vars"]);
                        // commit('m_freq_proj', response.data["freq_proj"]);
                        // commit('m_freq_wf', response.data["freq_wf"]);
                        state.pagination_v.total = response.data["total"];

                    }, error => {
                        console.log(error);
                    })
                    .finally(() => {
                        state.loading_v = false;
                        state.loading_cnv = false;
                    });
            }

        },


        a_f_filters({ state, commit, getters } ) {
            axios.post(process.env.VUE_APP_BE + '/f_filters', {}, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_f_filters', response.data["f_filters"]);
                }, error => {
                    console.log(error);
                });
        },


        a_workflows({ state, commit, getters }) {
            axios.get(process.env.VUE_APP_BE + '/workflows', { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_workflows', response.data["workflows"]);
                    commit('m_l_seq_technology', response.data["l_seq_technology"]);
                }, error => {
                    console.log(error);
                });
        },


        a_anots({ state, commit, getters }, payload) {
            // payload["cufi"] = state.cufi;
            axios.post(process.env.VUE_APP_BE + '/anots', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_transcripts', response.data["anots"]);
                }, error => {
                    console.log(error);
                });
        },


        a_show_qc({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/showqc', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/octet-stream', }, responseType: 'blob', })
                .then(response => {
                    var blob = new Blob([response.data]);
                    var filename = "QC_" + payload["id_project"] + ".xlsx"
                    FileSaver.saveAs(blob, filename);
                }, error => {
                    console.log("No data.");
                });
        },


        a_mongo_files({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/mongo_files', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if (response.data["redirect"] !== "") {
                        let redirect = response.data["redirect"];
                        window.open(redirect, '_blank');
                    } else if (response.data["info"] !== "") {
                        this.dispatch('a_notify', {
                            message: response.data["info"],
                        }, { root: true });
                        this.dispatch('a_project_files_data', { "id_project": state.id_project_detail });
                        this.dispatch('a_project_files_others', { "id_project": state.id_project_detail });
                    } else {

                    }
                }, error => {
                    console.log(error);
                });
        },


        a_project_files_data({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/project_files_data', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_project_files_data', response.data["f_list"]);
                }, error => {
                    console.log(error);
                });
        },


        a_project_files_others({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/project_files_others', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_project_files_others', response.data["f_list"]);
                }, error => {
                    console.log(error);
                });
        },


        a_change_interpretation({ state, commit, getters }, payload) {
            axios.post(process.env.VUE_APP_BE + '/change_interpretation', payload, { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    if ( ["b", "c"].includes(state.vars_selected_tab) ) {
                        this.dispatch('a_vars', { "sorting_prop": state.sorting_v.prop, "sorting_ord": state.sorting_v.ord, "perPage": state.pagination_v.perPage, "currentPage": state.pagination_v.currentPage, "totalPages": state.pagination_v.total, "fastras_freq": state.fastras_freq, "search_vars_by_select": state.search_vars_by_select, "search_vars_by_value": state.search_vars_by_value, "cufi": state.cufi, "id_examination": state.id_examination, "selected_exams": state.selected_exams, "id_project": state.id_project, "selected": state.vars_selected } );
                    } else {
                        this.dispatch('a_vars', { "sorting_prop": state.sorting_v.prop, "sorting_ord": state.sorting_v.ord, "perPage": state.pagination_v.perPage, "currentPage": state.pagination_v.currentPage, "totalPages": state.pagination_v.total, "fastras_freq": state.fastras_freq, "search_vars_by_select": state.search_vars_by_select, "search_vars_by_value": state.search_vars_by_value, "id_examination": state.id_examination, "selected_exams": state.selected_exams, "id_project": state.id_project, "selected": state.vars_selected } );
                    }
                    commit('m_modal_interpretation', false);
                    this.dispatch('a_notify', {'message': "Interpretation has been changed."} );
                }, error => {
                    console.log(error);
                });
        },


        a_acmg_data({ state, commit, }, id_var) {
            axios.get(process.env.VUE_APP_BE + '/acmg', { withCredentials: true, params: { "id_var": id_var }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                commit('m_acmg_data', response.data["acmg_data"]);
                commit('m_modal_acmg', true);
            }, error => {
            });
        },


        a_freq_data({ state, commit, }, payload) {
            axios.get(process.env.VUE_APP_BE + '/varsfreq', { withCredentials: true, params: { id_var: payload.row.id_var, id_subject: state.subject.id_subject, which: payload.which, whichid: state.id_project }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                commit('m_freq_data', response.data["freq_rows"]);
                commit('m_modal_freq', true);
            }, error => {
            });
        },


        a_predictors_data({ state, commit, }, id_var) {
            axios.get(process.env.VUE_APP_BE + '/predictors', { withCredentials: true, params: { "id_var": id_var }, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
            .then(response => {
                commit('m_predictors_data', response.data["predictors_data"]);
                commit('m_modal_predictors', true);
            }, error => {
            });
        },


        a_hpo_get({ state, commit, }) {
            let loading = Loading.service({
                lock: true,
                fullscreen: true,
                text: 'Loading',
                spinner: 'icofont-spinner icofont-10x',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            axios.get(process.env.VUE_APP_BE + '/hpo', { withCredentials: true, headers: { 'Authorization': state.token, 'RouterApp': state.rapp, 'Content-Type': 'application/json;charset=UTF-8', } })
                .then(response => {
                    commit('m_hpo', response.data["hpo"]);
                    commit('m_l_hpo', response.data["l_hpo"]);
                    loading.close();
                }, error => {
                    console.log(error);
                    loading.close();
                });
        },



    },



});

export default store;
