<template>
  <div class="dolu">

    <el-row v-if="application === 'NGS-GENOVESA'" type="flex" class="row-bg" justify="center" :gutter="20">
      <el-col :span="4">
        <div class="grid-content dolu">
          <a  v-show="!(token > '')" href="https://www.biovendor.group?utm_source=webgenovesa&amp;utm_medium=web-footer-logo" target="blank" class="logo" title="We bring to the market diagnostic tools for innovative methods of molecular diagnostics from BioVendor Group laboratories - NGS, LAMP and miRNA.">
            <img src="/img/bv/BG_NGS_logo.svg" alt="BioVendor Group ENG" />
          </a>
        </div>
      </el-col>
      <el-col :span="16">
        <div v-show="!fastgen && !vlngsa" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_a">
                Welcome to GENOVESA, the bioinformatic analysis portal.
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu text_a text_b">
                Get started:
              </div>
              <br  />
            </el-col>

            <el-col :span="20" :offset="4">
              <div class="grid-content">
                <p> <a href="https://youtu.be/e-to02RTkTE?si=FmqHJX1kyCm3WyWL" target="_blank"> Watch the Video Tutorial </a> </p>
                <p> <a href="https://www.biovendor.com/genovesa-bioinformatic-analysis-software/pds.pdf" target="_blank"> Read the User Guide </a> </p>
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              &nbsp;
              </div>
            </el-col>

            <el-col :span="5">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_5"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col v-show="!(token > '')" :span="24">
              <div class="grid-content dolu">
              &nbsp;
              </div>
            </el-col>

          </el-row>
        </div>

        <div v-show="fastgen" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_aa">
                 Three reasons why GENOVESA is worth your attention:
              </div>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    AUTOMATIC
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - generation of sequencing and target enrichment Quality Control
                  </div>
                  <div class="grid-content text_b">
                    - variant calling (SNV, InDel) and annotation
                  </div>
                  <div class="grid-content text_b">
                    - notification of low-coverage regions for simple filtering of relevant variants
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    DATABASES
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - update the annotations monthly ​
                  </div>
                  <div class="grid-content text_b">
                    - store patient data and variants internal​
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <div class="grid-content dolu text_a">
                    REPORTS
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content dolu text_b">
                    - on one-click​
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_5"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="go_back_welcome" class="my_butt_3"> Back </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-show="vlngsa" class="grid-content sedy_blok">
          <el-row>
            <el-col :span="24">
              <div class="grid-content dolu text_a">
                GENOVESA, a tailored pipeline for ViennaLab NGS Assays enables:​​
              </div>
            </el-col>
            <el-col :span="24">
              <div class="grid-content dolu text_b">
                - upload of NGS panel data (fastq-file format)​
              </div>
              <div class="grid-content text_b">
                - automatic generation of sequencing and target enrichment Quality Control​
              </div>
              <div class="grid-content text_b">
                - automatic variant calling (SNV, InDel) and annotation​
              </div>
              <div class="grid-content text_b">
                - optimized ViennaLab filters to identify biologically relevant variants ​
              </div>
              <div class="grid-content text_b">
                - customizable filters to allow comprehensive investigation of data ​
              </div>
              <div class="grid-content text_b">
                - visual display of SNV and InDel variants using a seamlessly integrated viewer ​
              </div>
              <div class="grid-content text_b">
                - report generation of significant genetic variants​
              </div>
            </el-col>

            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_login(true)" class="my_butt_1">Sign-In</el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="m_modal_reg(true)" class="my_butt_5"> Register </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content dolu">
                <el-button v-if="!(token > '')" @click="go_back_welcome" class="my_butt_3"> Back </el-button>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content dolu">
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content dolu">
              </div>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="4">
        <div class="grid-content text">
        </div>
      </el-col>
    </el-row>

  </div>


</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    components: {

    },


    data () {
      return {
        fastgen: false,
        vlngsa: false,

      }
    },

    computed: {
      ...mapState({
        token: state => state.token,
        application: state => state.application,

      }),

    },


    methods: {
      ...mapMutations({
        m_modal_login: 'm_modal_login',
        m_modal_reg: 'm_modal_reg',
      }),

      click_fastgen () {
        this.fastgen = true;
        this.vlngsa = false;
      },

      click_vlngsa () {
        this.fastgen = false;
        this.vlngsa = true;
      },

      go_back_welcome () {
        this.fastgen = false;
        this.vlngsa = false;
      },
    },


  }

</script>

<style scoped>
.dolu {
  padding-top: 45px !important;
}

.sedy_blok {
  background-color: #F4F4F3 !important;
}

.text_a {
  font-size: 32px !important;
  font-weight: 700;
  padding-left: 130px !important;
  padding-right: 130px !important;
  line-height: 44px !important;
}

.text_aa {
  font-size: 40px !important;
  font-weight: 700;
  padding-left: 130px !important;
  padding-right: 130px !important;
  line-height: 44px !important;
}

.text_b {
  font-size: 22px !important;
  font-weight: 700;
  padding-left: 130px !important;
  padding-right: 130px !important;
  line-height: 34px !important;
}

.butt_blue {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 32px !important;
  font-weight: 700;
  width: 100%;
  height: 110px;
}

.my_butt_1 {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
}

.my_butt_5 {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
}

.my_butt_3 {
  background-color: #1C3552 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 700;
  width: 100%;
  height: 60px;
}

</style>
