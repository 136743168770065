<template>
  <div class="row dolu_m">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div v-if="rapp === 'pro'" class="col-sm-5 title"> Genomics - projects </div>
        <div v-else-if="rapp === 'comgens'" class="col-sm-5 title"> Comparative Genomics - projects </div>
        <div v-else-if="rapp === 'fastras'" class="col-sm-5 title"> Fastgen - projects </div>
        <div v-else-if="rapp === 'genetify'" class="col-sm-5 title"> Microbiome - projects </div>
        <div v-else class="col-sm-5 title"> Projects </div>

        <div class="col-sm-2 ml-auto mr-auto">
          <el-button v-if="id_biorole > 3" type="primary" size="small" @click.native="add_project()">Add project</el-button>
            <el-tooltip content="Refresh table with projects" placement="top" effect="light" :open-delay="500">
              <el-button type="info" size="small" @click.native="refresh_projects">
                <i class="icofont-refresh icofont-1x"></i>
              </el-button>
            </el-tooltip>
        </div>

        <div class="col-sm-5">
          <div class="pull-right">
            <el-input size="medium"
                      placeholder="Search"
                      v-model="searchQuery_p"
                      suffix-icon="icofont-search-2">
            </el-input>
          </div>
        </div>

        <div class="col-sm-12 mt-2">
          <el-table class=""
            ref="projects"
            :data="projects_data"
            border
            highlight-current-row
            @current-change="handleCurrentChange_p"
            @sort-change="handleSortChange_p"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            empty-text=" "
            >
            <el-table-column v-for="column in tableColumns_p1"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              sortable
              >
            </el-table-column>

            <el-table-column
              label="Status"
              min-width="70"
              align="center"
              >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status > 14" size="small" effect="dark"> SIGNED OUT </el-tag>
                <el-tag v-else-if="scope.row.status > 11" size="small" effect="dark" type="info"> PASSED </el-tag>
                <el-tag v-else-if="scope.row.status > 9" size="small" effect="dark" type="success"> FINISHED </el-tag>
                <el-tag v-else-if="scope.row.status > 2" size="small" effect="dark" type="warning"> ANALYSING </el-tag>
                <el-tag v-else-if="scope.row.status > 0" size="small" effect="plain"> NEW </el-tag>
                <el-tag v-else size="small" type="default" plain>  </el-tag>
              </template>
            </el-table-column>


            <el-table-column
              v-if="rapp !== 'genetify'"
              label="CNV"
              width="80"
              align="center"
              >
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.cnv_status == 1" content="Waiting to upload at least 5 samples" placement="top" effect="light" :open-delay="500">
                  <el-tag size="mini" effect="plain" type="primary"> <i class="icofont-focus"></i> </el-tag>
                </el-tooltip>

                <el-tooltip v-else-if="scope.row.cnv_status == 2" content="At least 5 samples was uploaded. Wait to start CNV Analysis" placement="top" effect="light" :open-delay="500">
                  <el-tag size="mini" effect="success" type="info"> <i class="icofont-focus"></i> </el-tag>
                </el-tooltip>

                <el-tooltip v-else-if="scope.row.cnv_status == 3" content="CNV analysis failed" placement="top" effect="light" :open-delay="500">
                  <el-tag size="mini" effect="dark" type="danger"> <i class="icofont-focus"></i> </el-tag>
                </el-tooltip>

                <el-tooltip v-else-if="scope.row.cnv_status == 5" content="CNV Analysys in progress" placement="top" effect="light" :open-delay="500">
                  <el-tag size="mini" effect="dark" type="warning"> <i class="icofont-focus"></i> </el-tag>
                </el-tooltip>

                <el-tooltip v-else-if="scope.row.cnv_status == 9" content="CNV analysis finished successfully" placement="top" effect="light" :open-delay="500">
                  <el-tag size="mini" effect="dark" type="info"> <i class="icofont-focus"></i> </el-tag>
                </el-tooltip>

              </template>
            </el-table-column>

            <el-table-column
              prop="created"
              label="Created"
              min-width="50"
              align="center"
              >
              <template slot-scope="scope">
                  {{ moment(scope.row.created).isValid() ? moment(scope.row.created).format('DD. MM. YYYY') : "" }}
              </template>
            </el-table-column>

            <el-table-column
              prop="finished"
              label="Finished"
              min-width="50"
              align="center"
              >
              <template slot-scope="scope">
                    {{ moment(scope.row.finished).isValid() ? moment(scope.row.finished).format('DD. MM. YYYY') : "" }}
              </template>
            </el-table-column>

            <el-table-column v-for="column in tableColumns_p2"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              sortable
              >
            </el-table-column>

            <el-table-column
              :min-width="130"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <el-tooltip v-if="id_biorole > 3" content="Edit project, upload / download data" placement="top" effect="light" :open-delay="500">
                  <el-button type="primary" size="mini" @click="handle_project_detail(props.$index, props.row, $event)">
                    <i class="icofont-edit"></i>
                  </el-button>
                </el-tooltip>
                <!-- <el-tooltip content="Project validation" placement="top" effect="light">
                  <p-button type="primary" size="sm" icon @click="handle_unavailable(props.$index, props.row, $event)">
                    <i class="fas fa-check-square"></i>
                  </p-button>
                </el-tooltip> -->
                <el-tooltip v-if="props.row.status > 11" content="Global mapping quality" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click="handleShowQC(props.$index, props.row, $event)">
                    <i class="icofont-chart-bar-graph"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status > 11" content="FASTQ PROJECT QC" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click.prevent="handleShowProjectQC( props.$index, props.row, $event)">
                    <i class="icofont-chart-pie"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="props.row.status > 6 && id_biorole > 8" content="Reanalyze whole project" placement="top" effect="light" :open-delay="500">
                  <el-button type="info" size="mini" @click="handle_project_renew(props.$index, props.row, $event)">
                    <i class="icofont-reply-all"></i>
                  </el-button>
                </el-tooltip>

                <!-- <el-tooltip v-if="props.row.status > 6" content="Make global report" placement="top" effect="light" :open-delay="500">
                  <p-button type="success" size="sm" icon @click="handle_make_global_report(props.$index, props.row, $event)">
                    <i class="icofont-patient-file"></i>
                  </p-button>
                </el-tooltip>  -->

                <el-tooltip v-if="props.row.status < 3" content="Remove project" placement="top" effect="light" :open-delay="500">
                  <el-button type="danger" size="mini" @click="handle_project_delete(props.$index, props.row, $event)">
                    <i class="icofont-trash"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip v-if="props.row.status >11 && rapp === 'genetify' && subject.id_subject == 44" content="Print GENETIFY reports of this project" placement="top" effect="light" :open-delay="250">
                  <el-button type="success" size="mini" @click="print_gf_reports(props.$index, props.row, $event)">
                    <i class="icofont-file-pdf"></i>
                  </el-button>
                </el-tooltip>

              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="col-sm-6 pagination-info">
        </div>

        <div class="col-sm-6">
          <el-pagination
            class="pull-right pagination_mezera"
            @size-change="handleSizeChangePage_p"
            @current-change="handleCurrentChangePage_p"
            :current-page.sync="pagination_p.currentPage"
            :page-sizes="pagination_p.perPageOptions"
            :page-size="pagination_p.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination_p.total"
            background
            >
          </el-pagination>
        </div>
      </div>
    </div>


    <el-dialog title="New Project" :visible.sync="modal_new_project" width="40%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
      <h4 slot="header" class="title title-up">Add New project</h4>
      <form @submit.prevent="submit_np">
        <div class="row">
          <div class="col-md-12">
              <label>Project Name</label>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="" v-model="formdata_np.name">
              </div>

              <label>* Workflow</label>
              <div class="form-group">
                <el-select class="select-info"
                          placeholder="Select Workflow"
                          v-model="formdata_np.np_id_workflow">
                  <el-option v-for="option in g_workflows"
                            class="select-info"
                            :value="option.id_workflow"
                            :label="option.public_name"
                            :key="option.id_workflow">
                  </el-option>
                </el-select>
              </div>

              <label>* Sequencer Technology</label>
              <div class="form-group">
                <el-select class="select-info"
                          placeholder="Select Sequencer Technology"
                          v-model="formdata_np.np_id_st">
                  <el-option v-for="option in l_seq_technology"
                            class="select-info"
                            :value="option.id_st"
                            :label="option.name"
                            :key="option.id_st">
                  </el-option>
                </el-select>
              </div>

              <label>Description</label>
              <div class="form-group">
                <textarea type="textarea" class="form-control" placeholder="" v-model="formdata_np.description"> </textarea>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mr-auto ml-auto">
            <button :disabled="(formdata_np.np_id_workflow === '') || (formdata_np.np_id_st === '')" type="submit" class="btn btn-info">Submit</button>
          </div>
        </div>
      </form>
    </el-dialog>


    <el-dialog title="Edit Project" :visible.sync="modal_project_detail" width="80%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
      <h5 slot="header" class="title title-up">{{project_name}}</h5>

      <div class="row">
        <el-tabs class="col-md-12" type="card" @tab-click="load_data_files" v-model="mytab">
          <el-tab-pane label="Project Name" name="1">
            <div class="col-md-12">
              <form @submit.prevent="submit_ep">
                <div class="">
                  <div class="col-md-12">
                      <label>Name</label>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="" v-model="formdata_ep.name">
                      </div>

                      <label>Description</label>
                      <div class="form-group">
                        <textarea class="form-control" rows="8" placeholder="" v-model="formdata_ep.description"> </textarea>
                      </div>
                  </div>
                </div>

                <div class="">
                  <div class="col-md-2 mr-auto ml-auto">
                    <button type="submit" class="btn btn-info" style="width: 100%">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </el-tab-pane>

          <el-tab-pane label="FASTQ Files" name="q">
            <mongo-files collection="q" :modify="true" :myfiles="u_f_q" :extensions="['gz', 'fastq', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="Result Files" name="v">
            <mongo-files collection="v" :modify="false" :myfiles="u_f_v" :extensions="['vcf', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="MultiQC & Metadata Files" name="m">
            <mongo-files collection="m" :modify="false" :myfiles="u_f_m" :extensions="['csv', 'html', 'xlsx', 'xls', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="Other Files" name="o">
            <mongo-files collection="o" :modify="true" :myfiles="u_f_o" :extensions="['pdf', 'csv', 'tsv', 'xlsx', 'docx', 'doc', 'html', 'htm', 'odt', 'ods', 'mkv', 'jpg', 'png', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="BAM/BAI Files" name="b">
            <mongo-files collection="b" :modify="false" :myfiles="u_f_b" :extensions="['bam', 'bai', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp !== 'genetify'" label="CNV Files" name="c">
            <mongo-files collection="cnv" :modify="false" :myfiles="u_f_c" :extensions="['tsv', 'csv', 'bed', 'txt', 'xlsx', ]"> </mongo-files>
          </el-tab-pane>


          <el-tab-pane v-if="rapp === 'genetify'" label="Result Files" name="gf">
            <mongo-files collection="gf" :modify="true" :myfiles="u_f_gf" :extensions="['csv', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp === 'genetify'" label="Quality Control" name="gf_qc">
            <mongo-files collection="gf_qc" :modify="true" :myfiles="u_f_gf_qc" :extensions="['csv', 'pdf', ]"> </mongo-files>
          </el-tab-pane>

          <el-tab-pane v-if="rapp === 'genetify' && subject.id_subject < 1000" label="Metadata" name="gf_r">
            <mongo-files collection="gf_r" :modify="true" :myfiles="u_f_gf_r" :extensions="['csv', ]"> </mongo-files>
          </el-tab-pane>


        </el-tabs>

      </div>
    </el-dialog>

  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  // import {Table, TableColumn, Select, Option, Tabs, TabPane, Tooltip, Tag, Progress, Button } from 'element-ui';
  // import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';
  import Loading from 'src/components/Layout/LoadingMainPanel.vue';
  import Exams from 'src/components/Views/Exams.vue';
  import Vars from 'src/components/Views/Vars.vue';
  import MongoFiles from 'src/components/Views/MongoFiles.vue';

  import FileSaver from 'file-saver';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);

  // Vue.use(Table);
  // Vue.use(TableColumn);
  // Vue.use(Tabs);
  // Vue.use(Select);
  // Vue.use(Option);
  let notifier = new Vue();

  export default {
    name: 'projects',
    props: {
      rapp: String
    },
    components: {
      PPagination,
      // [Tabs.name]: Tabs,
      // [TabPane.name]: TabPane,
      // [Tag.name]: Tag,
      // [Tooltip.name]: Tooltip,
      [Exams.name]: Exams,
      [Vars.name]: Vars,
      [MongoFiles.name]: MongoFiles,
    },

    computed: {
      ...mapState({
        application: state => state.application,
        apps: state => state.apps,
        subject: state => state.subject,
        projects: state => state.projects,
        id_project: state => state.id_project,
        id_project_detail: state => state.id_project_detail,
        id_workflow: state => state.id_workflow,
        examinations: state => state.examinations,
        id_examination: state => state.id_examination,
        vars: state => state.vars,
        cufi: state => state.cufi,
        id_var: state => state.id_var,
        workflows: state => state.workflows,
        l_seq_technology: state => state.l_seq_technology,

        project_files_data: state => state.project_files_data,
        project_files_others: state => state.project_files_others,

        qc_redirect: state => state.qc_redirect,

        f_q: state => state.f_q,
        f_m: state => state.f_m,
        f_o: state => state.f_o,
        f_v: state => state.f_v,
        f_b: state => state.f_b,
        f_c: state => state.f_c,
        f_gf: state => state.f_gf,
        f_gf_qc: state => state.f_gf_qc,
        f_gf_r: state => state.f_gf_r,

        u_f_q: state => state.u_f_q,
        u_f_m: state => state.u_f_m,
        u_f_o: state => state.u_f_o,
        u_f_v: state => state.u_f_v,
        u_f_b: state => state.u_f_b,
        u_f_c: state => state.u_f_c,
        u_f_gf: state => state.u_f_gf,
        u_f_gf_qc: state => state.u_f_gf_qc,
        u_f_gf_r: state => state.u_f_gf_r,

        user: state => state.user,
        id_biorole: state => state.id_biorole,
        token: state => state.token,
        current_url: state => state.current_url,

      }),

      ...mapGetters({
        g_upload_data_file: 'g_upload_data_file',
        g_upload_csvfile: 'g_upload_csvfile',
        g_upload_xlsx: 'g_upload_xlsx',
        g_workflows: 'g_workflows',
      }),

      pagedData_p () {
        return this.projects.slice(this.from_p, this.to_p)
      },

      projects_data () {
        if (!this.searchQuery_p) {
          this.pagination_p.total = this.projects.length
          return this.pagedData_p
        }
        let result = this.projects
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch_p) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery_p)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination_p.total = result.length
        return result.slice(this.from_p, this.to_p)
      },

      to_p () {
        let highBound = this.from_p + this.pagination_p.perPage
        if (this.total_p < highBound) {
          highBound = this.total_p
        }
        return highBound
      },

      from_p () {
        return this.pagination_p.perPage * (this.pagination_p.currentPage - 1)
      },

      total_p () {
        this.pagination_p.total = this.projects.length
        return this.projects.length
      },

      modal_new_project: {
        get () { return this.$store.state.modal_new_project; },
        set (value) { this.$store.commit('m_modal_new_project', value); }
      },

      modal_project_detail: {
        get () { return this.$store.state.modal_project_detail; },
        set (value) { this.$store.commit('m_modal_project_detail', value); }
      },

    },


    data () {
      return {
        formdata_np: {
          name: "",
          np_id_workflow: "",
          np_id_st: "",
          description: "",
        },

        formdata_ep: {
          id_project: 0,
          name: "",
          description: "",
        },

        formdata_nss: {
          description: "",
        },

        formdata_patients: {
          metadata: "",
        },

        files: [],
        csv_file: [],
        xlsx_file: [],
        other_files: [],

        pagination_p: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery_p: '',
        propsToSearch_p: ['name', ],
        tableColumns_p1: [
          {
            prop: 'id_project',
            label: 'ID',
            minWidth: 30
          },
          {
            prop: 'name',
            label: 'Project Name',
            minWidth: 100
          },
          {
            prop: 'workflow',
            label: 'Workflow',
            minWidth: 130
          },
        ],

        tableColumns_p2: [
          {
            prop: 'description',
            label: 'Description',
            minWidth: 200,
          },

        ],

        project_name: "",
        mytab: "1",

      }
    },


    methods: {
      ...mapMutations({
        m_id_project: 'm_id_project',
        m_id_project_detail: 'm_id_project_detail',
        m_id_workflow: 'm_id_workflow',
        m_id_examination: 'm_id_examination',
        m_id_var: 'm_id_var',
        m_np_id_workflow: 'm_np_id_workflow',
        m_modal_new_project: 'm_modal_new_project',
        m_modal_project_detail: 'm_modal_project_detail',
        m_modal_project_stat: 'm_modal_project_stat',
        m_id_patient: 'm_id_patient',
        m_vars: 'm_vars',
        m_cufi_load: 'm_cufi_load',
        m_cufi_yes: 'm_cufi_yes',
        m_cufi_selected: 'm_cufi_selected',

        m_rapp: 'm_rapp',
        m_qc_redirect: 'm_qc_redirect',
        m_f_q: 'm_f_q',
        m_f_m: 'm_f_m',
        m_f_o: 'm_f_o',
        m_f_v: 'm_f_v',
        m_f_b: 'm_f_b',
        m_f_c: 'm_f_c',
        m_f_gf: 'm_f_gf',
        m_f_gf_qc: 'm_f_gf_qc',
        m_f_gf_r: 'm_f_gf_r',
        m_vars_tab_name: 'm_vars_tab_name',
        m_vars_selected_tab: 'm_vars_selected_tab',
        m_vars_selected: 'm_vars_selected',

      }),


      ...mapActions({
        a_projects: 'a_projects',
        a_examinations: 'a_examinations',
        a_vars: 'a_vars',
        a_workflows: 'a_workflows',
        a_project_new: 'a_project_new',
        a_project_update: 'a_project_update',
        a_project_delete: 'a_project_delete',
        a_project_renew: 'a_project_renew',
        a_show_qc: 'a_show_qc',
        a_mongo_files: 'a_mongo_files',
        a_project_files_data: 'a_project_files_data',
        a_project_files_others: 'a_project_files_others',
        a_notify: 'a_notify',

      }),


      handleDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },


      handle_project_detail (index, row, event) {
        event.stopImmediatePropagation();
        this.formdata_ep.id_project = row.id_project;
        this.formdata_ep.name = row.name;
        this.formdata_ep.description = row.description;
        this.m_id_project_detail(row.id_project);
        this.mytab = "1";

        this.files = [];
        this.other_files = [];
        this.csv_file = [];
        this.xlsx_file = [];
        this.m_modal_project_detail(true);
      },


      handle_close_modal_project_detail () {
        this.a_examinations( {"id_project": this.id_project_detail} );
      },


      handle_project_stat (index, row, event) {
        event.stopImmediatePropagation();
        // this.formdata_ep.id_project = row.id_project;
        // this.formdata_ep.name = row.name;
        // this.formdata_ep.description = row.description;
        this.m_id_project_detail(row.id_project);
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        this.m_modal_project_stat(true);
      },


      handle_make_global_report (index, row, event) {
        event.stopImmediatePropagation();
        // axios.get(this.privat_be + '/test', { withCredentials: true, params: { "xxx": 123 }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        // axios.get(this.privat_be + '/test', { params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
        axios.post(process.env.VUE_APP_BE + '/make_global_report', { id_project: row.id_project }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
                     var blob = new Blob([response.data]);
                     FileSaver.saveAs(blob, "global_report.pdf");
            }, error => {
                console.log(error);
            });
      },


      handle_project_delete (index, row, event) {
        event.stopImmediatePropagation();
        this.a_project_delete( {'id_project': row.id_project} );
      },


      handle_project_renew (index, row, event) {
        event.stopImmediatePropagation();

        this.$confirm('Are you sure to run reanalyze this run? Fee is 5 € / per each sample in run.', 'Warning!', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          center: true
        }).then(() => {
          this.a_project_renew( {'id_project': row.id_project} );
        }).catch(() => {

        });

      },


      print_gf_reports (index, row, event) {
        event.stopImmediatePropagation();

        this.$confirm('Are you sure to print reports of this project?', 'Warning!', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          center: true
        }).then(() => {
          // this.a_project_renew( {'id_project': row.id_project} );
        }).catch(() => {

        });

      },


      tableRowClassName({row, rowIndex}) {
        if (row.status === 0) {
          return 'row_0';
        } else if (row.status === 1) {
          return 'row_0';
        } else if (row.status === 2) {
          return 'row_0';
        } else if (row.status === 3) {
          return 'row_0';
        } else if (row.status === 4) {
          return 'row_0';
        } else {
          return 'row_0';
        }
      },


      handleShowQC (index, row, event) {
        // event.preventDefault();
        event.stopImmediatePropagation();
        // console.log(event);
        // this.m_id_project_detail(row.id_project);
        // axios.get(process.env.VUE_APP_BE + '/showqc', { params: { id_project: row.id_project },  headers: { 'Authorization': this.token } }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        //     .then(response => {
        //         if (response.data["redirect"] > "") {
        //             // window.open(response.data["redirect"], '_blank');
        //             // window.open("https://www.seznam.cz/", '_blank').focus();
        //             console.log(response.data["redirect"]);
        //         }
        //     }, error => {
        //         console.log(error);
        //     });

        // fetch(process.env.VUE_APP_BE + '/showqc?id_project=' + row.id_project, {   headers: { 'Authorization': this.token } }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        //   .then(async response => {
        //     const data = await response.json();
        //     let myredirect = data.redirect;
        //   })
        //   .finally(() => this.loading = false);
        //   console.log(myredirect);
          // window.open(myredirect, '_blank');
          // window.open("https://www.seznam.cz/", '_blank').focus();
          // console.log(this.qc_redirect);
          this.a_show_qc( { "id_project": row.id_project } );
          // this.a_notify( { "message": "HELLO!" } );
          // console.log(this.qc_redirect);

        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.m_modal_project_stat(true);
      },



      handleShowProjectQC (index, row, event) {
        event.stopImmediatePropagation();
        // this.m_id_project_detail(row.id_project);
        axios.get(process.env.VUE_APP_BE + '/showprojectqc', { withCredentials: true, params: { id_project: row.id_project}, headers: { 'Authorization': this.token, 'Content-Type': 'text/html', }, responseType: 'text', })
            .then(response => {
              var myWindow = window.open("", "_blank");
              myWindow.document.write(response.data);
              myWindow.document.close();
            }, error => {
                console.log(error);
            });
      },


      handleNotify (index, row, event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        // this.m_id_project_detail(row.id_project);

        this.a_notify( "TEST notifikace." );
        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.m_modal_project_stat(true);
      },


      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`)
      },


      submit_np(){
        this.a_project_new( this.formdata_np )
        this.m_modal_new_project(false);

      },


      submit_ep(){
        this.a_project_update( this.formdata_ep )
      },


      submit_nss(){
        this.a_project_update_ss( this.formdata_nss )
      },


      add_project(){
        moment.locale("us");
        this.formdata_np.name = "";
        this.formdata_np.np_id_workflow = "";
        this.formdata_np.np_id_st = "";
        this.formdata_np.description = "";
        // this.formdata_np.created_dt = moment(Date().toLocaleString()).format('LLL');
        this.m_modal_new_project(true);
        this.m_id_examination(0);
        this.m_id_project(0);
      },


      load_xlsx(){
        event.stopImmediatePropagation();
        notifier.$notify({ message: "Your file is loading from server. You've got it after transmittion will finished. Waiting, please.", type:"warning" });
        axios.get(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "mycol": "data", "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
              var blob = new Blob([response.data]);
              FileSaver.saveAs(blob, response.config.params.filename);
            }, error => {
                console.log(error);
            });
      },


      refresh_projects(){
        // event.stopImmediatePropagation();
        this.a_projects();
      },


      show_tab(tab, event){
        console.log(tab.name);
      },


      load_data_files(tab, event){
        let colls = ["q", "v", "m", "o", "b", "c", "gf", "gf_qc", "gf_r",];
        if (colls.includes(tab.name)) {
          // this.m_collection(tab.name);
          if (tab.name === "c") {
            var _col = "cnv";
          } else {
            var _col = tab.name;
          }
          axios.get(process.env.VUE_APP_BE + '/get_files', { withCredentials: true, params: { "id_project": this.id_project_detail, "collection": _col }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }})
              .then(response => {
                if (tab.name === "q") {
                  this.m_f_q(response.data["f_list"]);
                } else if (tab.name === "v") {
                  this.m_f_v(response.data["f_list"]);
                } else if (tab.name === "b") {
                  this.m_f_b(response.data["f_list"]);
                } else if (tab.name === "m") {
                  this.m_f_m(response.data["f_list"]);
                } else if (tab.name === "c") {
                  this.m_f_c(response.data["f_list"]);
                } else if (tab.name === "o") {
                  this.m_f_o(response.data["f_list"]);
                } else if (tab.name === "gf") {
                  this.m_f_gf(response.data["f_list"]);
                } else if (tab.name === "gf_qc") {
                  this.m_f_gf_qc(response.data["f_list"]);
                } else if (tab.name === "gf_r") {
                  this.m_f_gf_r(response.data["f_list"]);
                } else {
                }

              }, error => {
                  console.log(error);
              });
        }
      },

      // setCurrent(row) {
      //   this.$refs.singleTable.setCurrentRow(row);
      // },

      handleCurrentChange_p(val) {
        if (val && val.id_project > 0) {
          this.m_id_project(val.id_project);
          this.m_id_workflow(val.id_workflow);
          this.m_vars_tab_name("b");
          this.m_vars_selected_tab("b");
          this.m_id_examination(0);
          this.m_vars_selected(null);
          this.m_vars([]);
          this.a_examinations( {"id_project": val.id_project} );
          this.project_name = val.name;

          axios.get(process.env.VUE_APP_BE + '/cufi', { withCredentials: true, params: {id_workflow: val.id_workflow}, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8' } })
            .then(response => {
              if (response.data["id_cufi_default"] > 0) {
                this.m_cufi_selected( response.data["id_cufi_default"] );
                this.m_cufi_load();
                this.m_cufi_yes(true);
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
              } else {
                this.m_cufi_selected( 1 );
                this.m_cufi_load();
                this.m_vars_selected(null);
                this.a_vars( { "sorting_prop": this.sorting_v.prop, "sorting_ord": this.sorting_v.ord, "perPage": this.pagination_v.perPage, "currentPage": 1, "id_examination": this.id_examination, "id_project": this.id_project, "selected_exams": this.selected_exams, "cufi": this.cufi, "selected": this.vars_selected } );
              }
            }, error => {
              console.log(error);
            });

        } else {
          this.m_id_project(0);
          this.m_id_workflow(0);
          this.m_id_examination(0);
          this.project_name = "";
          this.m_vars_tab_name("b");
          this.m_vars_selected_tab("b");
          this.m_cufi_selected( 1 );
          this.m_cufi_load();
          this.m_vars_selected(null);
        }
      },


      handle_get_file(index, row, event) {
        event.stopImmediatePropagation();
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        // this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
        notifier.$notify({ message: "Your file is loading from server. You've got it after transmittion will finished. Waiting, please.", type:"warning" });
        axios.get(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "mycol": "data", "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
              var blob = new Blob([response.data]);
              FileSaver.saveAs(blob, response.config.params.filename);
              loading.close();
            }, error => {
                console.log(error);
                loading.close();
            });
      },


      handle_delete_file(index, row, event) {
        event.stopImmediatePropagation();
        // this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
        axios.delete(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "mycol": "q", "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, })
            .then(response => {
              notifier.$notify({ message: "Your file was deleted.", type:"info" });
              this.load_data_files_all();
              // this.dispatch('a_project_files_data', { "id_project": state.id_project_detail });
              // this.dispatch('a_project_files_others', { "id_project": state.id_project_detail });
            }, error => {
                console.log(error);
            });
      },


      handle_get_file_o(index, row, event) {
        event.stopImmediatePropagation();
        // this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
        notifier.$notify({ message: "Your file is loading from server. You've got it after transmittion will finished. Waiting, please.", type:"warning" });
        axios.get(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "mycol": "o", "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
              var blob = new Blob([response.data]);
              FileSaver.saveAs(blob, response.config.params.filename);
            }, error => {
                console.log(error);
            });
      },


      handle_close_modal () {

      },


      test_me () {
        // event.stopImmediatePropagation();
        // axios.get(this.privat_be + '/test', { withCredentials: true, params: { "xxx": 123 }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        // axios.get(this.privat_be + '/test', { params: { "mycol": "examinations_ext", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
        axios.get(process.env.VUE_APP_BE + '/get_file', { withCredentials: true, params: { "mycol": "q", "myid": 1, "myhash": "ad6d658ae29ee24c02c621a9a05f758c", "filename": "prehled_tema_497.csv" }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, responseType: 'blob', })
            .then(response => {
                     var blob = new Blob([response.data]);
                    //  console.log(response);
                     FileSaver.saveAs(blob, response.config.params.filename);
            }, error => {
                console.log(error);
            });
      },


      handleCurrentChangePage_p(val) {
        this.pagination_p.currentPage = val;
      },


      handleSizeChangePage_p(val) {
        this.pagination_p.perPage = val;
      },


      handleSortChange_p(col, prop, order) {
        // console.log(col, prop, order)

      },

      inputFile: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },


      inputFilexlsx: function (newFile, oldFile) {
        console.log('xlsx_files: ', this.xlsx_file)
        if (newFile && !newFile.active) {
          // Get response data
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
      },


      inputFileOther: function (newFile, oldFile) {
        if (newFile && !newFile.active) {
          // Get response data
          console.log('response', newFile.response)
          if (newFile.xhr) {
            //  Get the response status code
            this.load_data_files_all();
            console.log('status', newFile.xhr.status)
          }
        }
      },


      fetchEventsList () {
        this.a_projects(this.id_project);
      },


      cancelAutoUpdate () {
        clearInterval(this.timer)
      },


      watch_rapp () {
        // this.a_projects();
        this.m_rapp(this.rapp);
        this.refresh_projects();
      },


    },


    beforeCreate () {

    },

    beforeMount () {
      this.m_rapp(this.rapp);
      this.a_workflows();
      this.a_projects();
      this.m_id_patient(0);
      this.m_id_examination(0);
      this.m_id_project(0);
    },


   created () {
        // this.fetchEventsList();
        this.timer = setInterval(this.fetchEventsList, 30000)

    },


   mounted () {
      // this.a_projects();

    },


    beforeDestroy () {
      clearInterval(this.timer)
    },


    watch: {
      rapp: {
        handler: 'watch_rapp',
        immediate: true
      },
    },


  }
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}


.el-table .cell{
  line-height: 23px !important;
}

.el-table .el-table__cell{
  line-height: 23px !important;
}

.el-table td, .el-table th {
  padding: 1px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_3 {
  background: rgb(248, 186, 157) !important;
}

.el-table .row_4 {
  background: rgb(197, 248, 208) !important;
}

.el-table .row_9 {
  background: rgb(250, 197, 184) !important;
}



.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #0ada14 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

.dolu_m {
  padding-top: 15px !important;
}

.mezera {
  margin-right: 10px;
}

.lightgray {
  background-color: rgb(211, 208, 203) !important;
}

.el-tag {
  margin-left: 2px !important;
}

.pagination_mezera {
  margin-top: 25px !important;
}

</style>
