<template>
  <div class="row">
    <div class="col-md-12 card red_box" v-if="$refs.upload && $refs.upload.active" v-loading="$refs.upload && $refs.upload.active" element-loading-spinner="el-icon-loading" element-loading-text="Upload in progress. Please, do not close this window.">
    </div>

    <div v-if="modify" v-for="file in x_myfiles" class="col-md-3">
      <el-progress :text-inside="true" :stroke-width="25" :percentage="Math.round(file.progress)"></el-progress> {{file.name.substring(0, file.name.search("fastq")-1)}} <br /> {{ Object.keys(file.response).length > 0 ? file.response : "Ready for upload." }}
    </div>

    <div v-if="modify" class="col-md-12">
      <file-upload
        class="btn btn-primary"
        ref="upload"
        :input-id=this.collection
        v-model=x_myfiles
        :multiple="true"
        :size=this.mysize
        :post-action="g_upload_file"
        @input-file="inputFile"
        :headers="{
            Authorization: this.token,
            id_project: this.id_project_detail,
            collection: this.collection,
            rapp: this.rapp
        }"
        :data="{
            token: this.token,
            id_project: this.id_project_detail,
            collection: this.collection,
            rapp: this.rapp
        }"

      >
      SELECT FILES
      </file-upload>
      <span class="mezera"> </span>
      <button class="btn btn-primary" v-show="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true" type="button">Start upload</button>
      <button class="btn btn-warning" v-show="$refs.upload && $refs.upload.active" @click.prevent="$refs.upload.active = false" type="button">Stop upload</button>
    </div>


    <div class="col-md-12 card lightgray">
      <div class="card-header">
        <div class="category"></div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-button v-if="rapp === 'genetify' && collection !== 'q'" type="primary" size="mini" @click="download_all()"> DOWNLOAD ALL </el-button>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class=""
            ref="my_table"
            :data="tableData"
            border
            highlight-current-row
            @current-change="handleCurrentChange"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            empty-text=" "
          >
            <el-table-column v-for="column in tableColumns"
                            :key="column.label"
                            :min-width="column.minWidth"
                            :prop="column.prop"
                            :label="column.label"
                            sortable
                            >
            </el-table-column>
            <el-table-column
              :min-width="100"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <el-tooltip content="Download file" placement="top" effect="light" :open-delay="500">
                  <el-button type="primary" size="mini" @click="handle_get_file( props.$index, props.row, $event, collection )">
                    <i class="fas fa-download"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip content="Remove file" placement="top" effect="light" :open-delay="500">
                  <el-button type="danger" size="mini" @click="handle_delete_file( props.$index, props.row, $event, collection )">
                    <i class="fas fa-trash-alt"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from_p + 1}} to {{to_p}} of {{total_p}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total"
                        type="light"
                        >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  import {Table, TableColumn, Select, Option, Tabs, TabPane, Tooltip, Progress, Loading } from 'element-ui';
  import Button from 'src/components/UIComponents/Button';
  import PPagination from 'src/components/UIComponents/Pagination.vue';

  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import axios from 'axios';
  import VueUploadComponent from 'vue-upload-component';
  Vue.component('file-upload', VueUploadComponent);
  import FileSaver from 'file-saver';
  import { numeric } from 'vee-validate/dist/rules';

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Tabs);
  Vue.use(Select);
  Vue.use(Option);
  Vue.use(Loading);

  let notifier = new Vue();


  export default {
    name: 'mongo-files',
    props: {
      collection: {
        type: String,
        default: 'x',
        description: ''
      },
      modify: {
        type: Boolean,
        default: true,
        description: ''
      },
      myfiles: {
        type: Array,
        default: [],
        description: ''
      },
      mysize: {
        type: Number,
        default: 128000000000,
        description: ''
      },

    },
    components: {
      PPagination,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Tooltip.name]: Tooltip,
    },
    computed: {
      ...mapState({
        projects: state => state.projects,
        id_project: state => state.id_project,
        id_project_detail: state => state.id_project_detail,
        examinations: state => state.examinations,
        rapp: state => state.rapp,

        f_q: state => state.f_q,
        f_m: state => state.f_m,
        f_o: state => state.f_o,
        f_v: state => state.f_v,
        f_b: state => state.f_b,
        f_c: state => state.f_c,
        f_gf: state => state.f_gf,
        f_gf_qc: state => state.f_gf_qc,
        f_gf_r: state => state.f_gf_r,

        u_f_q: state => state.u_f_q,
        u_f_m: state => state.u_f_m,
        u_f_o: state => state.u_f_o,
        u_f_v: state => state.u_f_v,
        u_f_b: state => state.u_f_b,
        u_f_c: state => state.u_f_c,
        u_f_gf: state => state.u_f_gf,
        u_f_gf_qc: state => state.u_f_gf_qc,
        u_f_gf_r: state => state.u_f_gf_r,

        project_detail: state => state.project_detail,

        user: state => state.user,
        token: state => state.token,
        current_url: state => state.current_url,

      }),


      ...mapGetters({
        g_upload_file: 'g_upload_file',
      }),


      pagedData () {
        if (this.collection === "v") {
          return this.f_v.slice(this.from_p, this.to_p);
        } else if (this.collection === "q") {
          return this.f_q.slice(this.from_p, this.to_p);
        } else if (this.collection === "m") {
          return this.f_m.slice(this.from_p, this.to_p);
        } else if (this.collection === "o") {
          return this.f_o.slice(this.from_p, this.to_p);
        } else if (this.collection === "b") {
          return this.f_b.slice(this.from_p, this.to_p);
        } else if (this.collection === "cnv") {
          return this.f_c.slice(this.from_p, this.to_p);
        } else if (this.collection === "gf") {
          return this.f_gf.slice(this.from_p, this.to_p);
        } else if (this.collection === "gf_qc") {
          return this.f_gf_qc.slice(this.from_p, this.to_p);
        } else if (this.collection === "gf_r") {
          return this.f_gf_r.slice(this.from_p, this.to_p);
        } else {
          return [];
        }

      },


      tableData () {
        if (!this.searchQuery) {
          if (this.collection === "v") {
            this.pagination.total = this.f_v.length;
            let tempData = this.u_f_v;
          } else if (this.collection === "q") {
            this.pagination.total = this.f_q.length;
            let tempData = this.f_q;
          } else if (this.collection === "m") {
            this.pagination.total = this.f_m.length;
            let tempData = this.f_m;
          } else if (this.collection === "o") {
            this.pagination.total = this.f_o.length;
            let tempData = this.f_o;
          } else if (this.collection === "b") {
            this.pagination.total = this.f_b.length;
            let tempData = this.f_b;
          } else if (this.collection === "cnv") {
            this.pagination.total = this.f_c.length;
            let tempData = this.f_c;
          } else if (this.collection === "gf") {
            this.pagination.total = this.f_gf.length;
            let tempData = this.f_gf;
          } else if (this.collection === "gf_qc") {
            this.pagination.total = this.f_gf_qc.length;
            let tempData = this.f_gf_qc;
          } else if (this.collection === "gf_r") {
            this.pagination.total = this.f_gf_r.length;
            let tempData = this.f_gf_r;
          } else {
            this.pagination.total = 0;
            let tempData = [];
          }
          return this.pagedData;
        }
        let result = tempData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from_p, this.to_p)
      },


      to_p () {
        let highBound = this.from_p + this.pagination.perPage
        if (this.total_p < highBound) {
          highBound = this.total_p
        }
        return highBound
      },


      from_p () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },


      total_p () {
        if (this.collection === "v") {
          this.pagination.total = this.f_v.length;
          return this.f_v.length;
        } else if (this.collection === "q") {
          this.pagination.total = this.f_q.length;
          return this.f_q.length;
        } else if (this.collection === "m") {
          this.pagination.total = this.f_m.length;
          return this.f_m.length;
        } else if (this.collection === "o") {
          this.pagination.total = this.f_o.length;
          return this.f_o.length;
        } else if (this.collection === "b") {
          this.pagination.total = this.f_b.length;
          return this.f_b.length;
        } else if (this.collection === "cnv") {
          this.pagination.total = this.f_c.length;
          return this.f_c.length;
        } else if (this.collection === "gf") {
          this.pagination.total = this.f_gf.length;
          return this.f_gf.length;
        } else if (this.collection === "gf_qc") {
          this.pagination.total = this.f_gf_qc.length;
          return this.f_gf_qc.length;
        } else if (this.collection === "gf_r") {
          this.pagination.total = this.f_gf_r.length;
          return this.f_gf_r.length;
        } else {
          this.pagination.total = 0;
          return 0;
        }
      },


    },


    data () {
      return {
        files: {
          "f_q": [],
          "f_v": [],
          "f_m": [],
          "f_o": [],
          "f_b": [],
          "f_c": [],
          "f_x": [],
          "f_gf": [],
          "f_gf_qc": [],
          "f_gf_r": [],
        },
        my_extensions: {
          "f_q": ['gz', 'fastq', ],
          "f_v": ['vcf', ],
          "f_m": ['csv', 'html', 'xlsx', ],
          "f_o": ['pdf', 'csv', 'tsv', 'xlsx', 'docx', 'doc', 'html', 'htm', 'odt', 'ods', 'mkv', 'jpg', 'png', ],
          "f_b": ['bam', 'bai', ],
          "f_c": ['tsv', 'csv', 'bed', 'txt', 'xlsx', ],
          "f_gf": ['csv', ],
          "f_gf_qc": ['csv', 'pdf', ],
          "f_gf_r": ['csv', ],
        },

        x_myfiles: this.myfiles,

        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, ],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name', ],
        tableColumns: [
          {
            prop: 'filename',
            label: 'File name',
            minWidth: 120,
            align: "left"
          },
          {
            prop: 'md5',
            label: 'MD5',
            minWidth: 120,
            align: "left"
          },

        ],
      }
    },


    methods: {
      tableRowClassName({row, rowIndex}) {
        if (row.status === 0) {
          return 'row_0';
        } else if (row.status === 1) {
          return 'row_0';
        } else if (row.status === 2) {
          return 'row_0';
        } else if (row.status === 3) {
          return 'row_0';
        } else if (row.status === 4) {
          return 'row_0';
        } else {
          return 'row_0';
        }
      },


      customAction({file, component}) {
        console.log(file);
        console.log(component);

      },




      handleShowQC (index, row, event) {
        // event.preventDefault();
        event.stopImmediatePropagation();
        // console.log(event);
        // this.m_id_project_detail(row.id_project);
        // axios.get(process.env.VUE_APP_BE + '/showqc', { params: { id_project: row.id_project },  headers: { 'Authorization': this.token } }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        //     .then(response => {
        //         if (response.data["redirect"] > "") {
        //             // window.open(response.data["redirect"], '_blank');
        //             // window.open("https://www.seznam.cz/", '_blank').focus();
        //             console.log(response.data["redirect"]);
        //         }
        //     }, error => {
        //         console.log(error);
        //     });

        // fetch(process.env.VUE_APP_BE + '/showqc?id_project=' + row.id_project, {   headers: { 'Authorization': this.token } }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', } })
        //   .then(async response => {
        //     const data = await response.json();
        //     let myredirect = data.redirect;
        //   })
        //   .finally(() => this.loading = false);
        //   console.log(myredirect);
          // window.open(myredirect, '_blank');
          // window.open("https://www.seznam.cz/", '_blank').focus();
          // console.log(this.qc_redirect);
          this.a_show_qc( { "id_project": row.id_project } );
          // this.a_notify( { "message": "HELLO!" } );
          // console.log(this.qc_redirect);

        // this.files = [];
        // this.other_files = [];
        // this.csv_file = [];
        // this.m_modal_project_stat(true);
      },


      handleNotify (index, row, event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.a_notify( "TEST notifikace." );
      },


      handle_unavailable (index, row, event) {
        event.stopImmediatePropagation();
        alert(`Not available in trial version.`)
      },


      submit_project_detail(){
        this.a_project_detail(this.project_detail)
        this.m_modal_project_detail(false);
      },

      submit_ep(){
        this.a_project_update(this.formdata_ep)
      },


      // load_my_files () {
      //   this.a_load_mongo_files( {'id_project': this.id_project_detail, "collection": this.collection} )
      // },


      // handle_mongo_files(index, row, event, type) {
      //   event.stopImmediatePropagation();
      //   this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
      // },


      handleCurrentChange(val) {
        if (val && val.id_var > 0) {
          // this.a_examinations(val.id_examination);
        } else {
          // this.m_i_examination(0);
        }
      },


      inputFile: function (newFile, oldFile) {
        console.log(newFile);
        console.log(oldFile);

        if (newFile && !newFile.active) {
          if (newFile.xhr) {

            axios.get(process.env.VUE_APP_BE + '/get_files', { withCredentials: true, params: { "id_project": this.id_project_detail, "collection": this.collection }, headers: { 'Authorization': this.token, 'RouterApp': this.rapp, 'Content-Type': 'application/json;charset=UTF-8', }})
                .then(response => {
                  if (this.collection === "q") {
                    this.m_f_q(response.data["f_list"]);
                  } else if (this.collection === "v") {
                    this.m_f_v(response.data["f_list"]);
                  } else if (this.collection === "m") {
                    this.m_f_m(response.data["f_list"]);
                  } else if (this.collection === "o") {
                    this.m_f_o(response.data["f_list"]);
                  } else if (this.collection === "b") {
                    this.m_f_b(response.data["f_list"]);
                  } else if (this.collection === "cnv") {
                    this.m_f_c(response.data["f_list"]);
                  } else if (this.collection === "gf") {
                    this.m_f_gf(response.data["f_list"]);
                  } else if (this.collection === "gf_qc") {
                    this.m_f_gf_qc(response.data["f_list"]);
                  } else if (this.collection === "gf_r") {
                    this.m_f_gf_r(response.data["f_list"]);
                  } else {
                  }

                  if (this.$refs.upload.uploaded) {
                    axios.post(process.env.VUE_APP_BE + '/uploadfilestatus', { "id_project": this.id_project_detail, }, { withCredentials: true, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }})
                      .then(response => {
                        console.log("UPLOAD DOKONCEN");
                      }, error => {
                          console.log(error);
                      });

                    setTimeout(() => {
                      this.$refs.upload.clear();
                    }, 10000);
                  }

                }, error => {
                    console.log(error);
                });

            this.a_examinations( {"id_project": this.id_project_detail} );
          }
        }
      },


      handle_get_file(index, row, event, collection) {
        event.stopImmediatePropagation();
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        // this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
        notifier.$notify({ message: "Downloading file. Please wait.", type:"warning" });
        axios.get(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "collection": collection, "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename, "_id": row._id }, headers: { 'Authorization': this.token, 'Content-Type': 'application/octet-stream', }, responseType: 'blob', })
            .then(response => {
              console.log(response.config);
              var blob = new Blob([response.data]);
              FileSaver.saveAs(blob, response.config.params.filename);
              loading.close();
            }, error => {
                console.log(error);
                loading.close();
            });
      },


      handle_delete_file(index, row, event, collection) {
        event.stopImmediatePropagation();
        // this.a_mongo_files( {"id_project": this.id_project_detail, "md5": row.md5, "action_type": type} );
        axios.delete(process.env.VUE_APP_BE + '/mongo_be_file', { withCredentials: true, params: { "collection": collection, "id_project": this.id_project_detail, "myhash": row.md5, "filename": row.filename, "_id": row._id }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }, })
            .then(response => {
              notifier.$notify({ message: "Your file was deleted.", type:"info" });
              // this.dispatch('a_project_files_data', { "id_project": state.id_project_detail });
              // this.dispatch('a_project_files_others', { "id_project": state.id_project_detail });

              axios.get(process.env.VUE_APP_BE + '/get_files', { withCredentials: true, params: { "id_project": this.id_project_detail, "collection": collection }, headers: { 'Authorization': this.token, 'Content-Type': 'application/json;charset=UTF-8', }})
                  .then(response => {
                    // console.log(response.data["f_list"]);
                    if (collection === "q") {
                      this.m_f_q(response.data["f_list"]);
                    } else if (collection === "v") {
                      this.m_f_v(response.data["f_list"]);
                    } else if (collection === "m") {
                      this.m_f_m(response.data["f_list"]);
                    } else if (collection === "o") {
                      this.m_f_o(response.data["f_list"]);
                    } else if (collection === "b") {
                      this.m_f_b(response.data["f_list"]);
                    } else if (collection === "cnv") {
                      this.m_f_c(response.data["f_list"]);
                    } else if (collection === "gf") {
                      this.m_f_gf(response.data["f_list"]);
                    } else if (collection === "gf_qc") {
                      this.m_f_gf_qc(response.data["f_list"]);
                    } else if (collection === "gf_r") {
                      this.m_f_gf_r(response.data["f_list"]);
                    } else {
                    }

                  }, error => {
                      console.log(error);
                  });

            }, error => {
                console.log(error);
            });
      },


      download_all(collection) {
        event.stopImmediatePropagation();
        const loading = this.$loading({
          lock: true,
          fullscreen: true,
          body: true,
          text: 'Loading',
          spinner: 'el-icon-loading ',
          background: 'rgba(0, 0, 0, 0.5)'
        });

        notifier.$notify({ message: "Downloading file. Please wait.", type:"warning" });

        axios.get(process.env.VUE_APP_BE + '/download_files', { withCredentials: true, params: { "id_project": this.id_project_detail, "collection": this.collection }, headers: { 'Authorization': this.token, 'Content-Type': 'application/octet-stream', }, responseType: 'blob', })
          .then(response => {
          var blob = new Blob([response.data]);
          FileSaver.saveAs(blob, `genovesa_${this.id_project_detail}_${this.collection}.zip`);
          loading.close();
        }, error => {
            console.log(error);
            loading.close();
        });
      },



      ...mapMutations({
        m_id_project: 'm_id_project',
        m_id_project_detail: 'm_id_project_detail',
        m_modal_project_detail: 'm_modal_project_detail',
        m_modal_project_stat: 'm_modal_project_stat',
        m_f_q: 'm_f_q',
        m_f_m: 'm_f_m',
        m_f_o: 'm_f_o',
        m_f_v: 'm_f_v',
        m_f_b: 'm_f_b',
        m_f_c: 'm_f_c',
        m_f_gf: 'm_f_gf',
        m_f_gf_qc: 'm_f_gf_qc',
        m_f_gf_r: 'm_f_gf_r',
      }),


      ...mapActions({
        a_mongo_files: 'a_mongo_files',
        a_notify: 'a_notify',
        a_examinations: 'a_examinations',

      }),

      // setCurrent(row) {
      //   this.$refs.singleTable.setCurrentRow(row);
      // },



    },

    mounted () {
      this.$watch(
        () => {
          if (this.$refs.upload) {
            return this.$refs.upload.uploaded;
          }
        },
        (val) => {
          if (val) {
            this.$alert('File upload finished. It is safe to close the window now (top right corner).', 'FINISHED', {
              confirmButtonText: 'OK',
              type: "info",
            });
          }
        }
      );

    },

  }
</script>

<style scoped lang="scss">
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}

.el-table {
  font-size: 12px !important;
  color: black !important;

}

.el-table .cell{
  line-height: 20px !important;
}

.el-table td, .el-table th {
  padding: 3px 0 !important;
}

.el-table .row_0 {
  background: rgb(253, 253, 253) !important;
}

.el-table .row_1 {
  background: rgb(243, 245, 152) !important;
}

.el-table .row_2 {
  background: rgb(248, 221, 171) !important;
}

.el-table .row_3 {
  background: rgb(248, 186, 157) !important;
}

.el-table .row_4 {
  background: rgb(197, 248, 208) !important;
}

.el-table .row_9 {
  background: rgb(250, 197, 184) !important;
}



.el-table .warning-row {
  background: rgb(241, 175, 52) !important;
}

.el-table .success-row {
  background: #0ada14 !important;
}

.el-table .info-row {
  background: #8fbdf1 !important;
}

h4 {
  margin-top: 5px !important;
}

.dolu {
  padding-top: 80px !important;
}

.table > thead > tr > th, .el-table table > thead > tr > th {
  font-size: 12px !important;
}

.mezera {
  margin-right: 10px;
}

.lightgray {
  background-color: rgb(211, 208, 203) !important;
}

.sub-title {
  font-size: 0.7em;
  margin-bottom: 1px;
  margin-top: 10px;
  color: black;
  text-transform: uppercase;
}

.el-icon-info {
    font-family: element-icons !important;
    font-style: normal;
    font-weight: 800;
    font-size:100px !important;
    color: white;
    background-color: white !important;
}

.red_box {
    background-color: rgb(49, 38, 37) !important;
    min-height: 330px;
    font-size: 100px !important;
    color: rgb(252, 252, 252) !important;
}

.green_box {
    background-color: rgb(14, 83, 23) !important;
    min-height: 150px;
    font-size: 50px !important;
    color: rgb(252, 252, 252) !important;
}

</style>
